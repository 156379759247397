import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { RouterLink, RouterLinkActive, RouterModule } from "@angular/router";
import hash from "hash-it";
import { BreakpointSize } from "src/app/helpers/types/layouts.type";
export type ViewLink = {
  routerLink: typeof RouterLink.prototype.routerLink;
  routerLinkActiveOptions?: typeof RouterLinkActive.prototype.routerLinkActiveOptions;
  queryParamsHandling?: typeof RouterLink.prototype.queryParamsHandling;
  queryParams?: typeof RouterLink.prototype.queryParams;
  label: string;
};
@Component({
  selector: "fd-view-links",
  standalone: true,
  imports: [CommonModule, RouterModule, MatChipsModule, MatButtonModule, MatIconModule, MatMenuModule],
  templateUrl: "./fd-view-links.component.html",
  styleUrls: ["./fd-view-links.component.scss"],
})
export class FdViewLinksComponent implements OnInit {
  @Input() links: ViewLink[] = [];
  @Input() routerLinkActiveOptions: typeof RouterLinkActive.prototype.routerLinkActiveOptions = { exact: true };
  @Input() queryParamsHandling: typeof RouterLink.prototype.queryParamsHandling = "merge";
  @Input() breakpoint: BreakpointSize = "md";

  active: string;
  linkData: ViewLink[];

  ngOnInit() {
    this.active = this.links[0]?.label;
  }

  onRouterLinkActive(active: boolean, label: string) {
    if (active) this.active = label;
  }
  
  trackByItems(index: number, item: ViewLink): number {
    // Only update *ngFor when items actually change
    // Without this, it sees a new array every time and will always re-render causing an infinite loop (https://github.com/angular/angular/issues/20995)
    return hash(item);
  }

}
