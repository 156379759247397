<!--
  Content Projection Template wrappers.
  These are needed to allow potentially outputting slots multiple times and in different places
-->
<ng-template #breadcrumbs><ng-content select="fd-layout-breadcrumbs" /></ng-template>
<ng-template #fixedFooter><ng-content select="fd-layout-fixed-footer" /></ng-template>
<ng-template #headerPrimary><ng-content select="fd-layout-header-primary" /></ng-template>
<ng-template #headerTitle><ng-content select="fd-layout-title" /></ng-template>
<ng-template #headerSecondary><ng-content select="fd-layout-header-secondary" /></ng-template>
<ng-template #sidenav><ng-content select="fd-layout-sidenav" /></ng-template>
<ng-template #sidebar><ng-content select="fd-layout-sidebar" /></ng-template>
<ng-template #debug><ng-content select="fd-layout-debug" /></ng-template>
<ng-template #mainContent>
  <fd-spinner *ngIf="showSpinner" />
  <ng-content select="fd-layout-content" />
  <ng-content /><!-- Include extraneous content as part of mainContent -->
</ng-template>

<ng-template #header let-drawer="drawer">
  <header class="mat-elevation-z1 z-1 py-2 py-sm-3">
    <div class="flex-stack gap-0 {{ config.container | containerClassName }} mat-form-field-abs-hint">
      <ng-container *ngIf="config.showBreadcrumbs" [ngTemplateOutlet]="breadcrumbs" />
      <!-- Row 1 -->
      <div class="flex-spaced justify-content-between align-items-center">
        <div class="flex-spaced align-items-baseline">
          <h1 *ngIf="title" class="mb-0">{{ title }}</h1>
          <ng-container [ngTemplateOutlet]="headerTitle" />
        </div>
        <div class="flex-spaced ms-auto">
          <ng-container [ngTemplateOutlet]="headerPrimary" />
        </div>
      </div>

      <!-- Row 2 -->
      <ng-container [ngTemplateOutlet]="headerSecondary" />
    </div>
  </header>
</ng-template>

<mat-sidenav-container *ngIf="config.type !== 'basic'" class="fill-page" [class.show-spinner]="showSpinner">
  <mat-sidenav #drawer position="end" class="p-3">
    <button class="close" (click)="drawer.toggle()" matTooltip="Close">
      <mat-icon>close</mat-icon>
    </button>
    <ng-container [ngTemplateOutlet]="sidenav" />
  </mat-sidenav>
  <mat-sidenav-content class="flex-fill">
    <ng-container [ngTemplateOutlet]="header" [ngTemplateOutletContext]="{ drawer: drawer }" />
    <div class="flex-fill" *ngIf="config.type === 'sidenav'">
      <ng-container [ngTemplateOutlet]="mainContent" />
    </div>
    <div class="sidebar-left {{ config.container | containerClassName }}" *ngIf="config.type === 'sidebar'">
      <div class="show-when-app-sidebar surface overflow-auto my-3">
        <ng-container [ngTemplateOutlet]="sidebar" />
      </div>
      <div class="flex-fill">
        <ng-container [ngTemplateOutlet]="mainContent" />
      </div>
    </div>
    <ng-container [ngTemplateOutlet]="debug" />
    <ng-container [ngTemplateOutlet]="fixedFooter" />
  </mat-sidenav-content>
</mat-sidenav-container>

<div *ngIf="config.type === 'basic'" class="flex-fill">
  <ng-container [ngTemplateOutlet]="header" />
  <div class="flex-fill">
    <ng-container [ngTemplateOutlet]="mainContent" />
  </div>
  <ng-container [ngTemplateOutlet]="debug" />
  <ng-container [ngTemplateOutlet]="fixedFooter" />
</div>
