import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";

@Component({
  selector: "fd-layout-breadcrumbs",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./fd-layout-breadcrumbs.component.html",
  styleUrls: ["./fd-layout-breadcrumbs.component.scss"],
})
export class FdLayoutBreadcrumbsComponent {}
