import { Component } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@Component({
  selector: "fd-spinner",
  standalone: true,
  templateUrl: "./fd-spinner.component.html",
  styleUrls: ["./fd-spinner.component.scss"],
  imports: [MatProgressSpinnerModule],
})
export class FdSpinnerComponent {}
