import { DestroyRef, Directive, OnInit, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { NgControl } from "@angular/forms";

// formControlName/formControl used multiple times for the same control doesn't keep the UI in sync
// This directive listens for changes to the attached control and updates the value.
// See: https://github.com/angular/angular/issues/10036 / https://github.com/angular/angular/issues/51239

@Directive({
  /* eslint-disable */
  selector: "[formControlName],[formControl]",
  standalone: true,
})
export class FormControlSyncDirective implements OnInit {
  controlDirective = inject(NgControl);
  destroyRef = inject(DestroyRef);
  ngOnInit() {
    this.controlDirective?.control.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      this.controlDirective.control.setValue(value, { emitEvent: false });
    });
  }
}
