/** The cached data contract for the current user */
export interface UserDtoInterface {
  id: string;
  impersonatingIdentityId?: string;
  userId: number;
  email: string;
  firstName: string;
  lastName: string;
  emailConfirmed: boolean;
  roles: string[];
  isInRole: (...role: string[]) => boolean;
  hasAnyViewRole: (rolePrefix: string) => boolean;
}

export class UserDtoModel implements UserDtoInterface {
  id: string;
  impersonatingIdentityId?: string;
  userId: number;
  email: string;
  firstName: string;
  lastName: string;
  emailConfirmed: boolean;
  roles: string[];

  /**
   * Returns true if the logged in user has any "view" role with the provided prefix or if `rolePrefix` is falsey.
   * @param role
   */
  hasAnyViewRole(rolePrefix: string) {
    if (!rolePrefix) {
      return true;
    }

    return this.roles && this.roles.some(r => r.startsWith(rolePrefix));
  }

  /**
   * Returns true if the logged in user has any of the provided role or if `role` is falsey.
   * @param role
   */
  isInRole(...role: string[]) {
    if (!role || role[0] == undefined) {
      return true;
    }
    return this.roles && this.roles.some(r => role.indexOf(r) !== -1);
  }
}
