import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { AppEnvironment } from "./app/enums/repository/app-environment.enum";
import { environment } from "./environments/environment";

// Prototyping
import "./app/helpers/prototyping/array";
import "./app/helpers/prototyping/date";
import "./app/helpers/prototyping/element";
import "./app/helpers/prototyping/number";

if (environment.configuration === AppEnvironment.Production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
