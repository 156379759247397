<fd-button
  [matButton]="matButton"
  [color]="color"
  [disabled]="disabled"
  [menu]="menu"
  (click)="isExportingOption ? cancelExport() : null">
  <fd-loading-label
    icon="download"
    [isLoading]="!!isExportingOption"
    tooltipText="Cancel {{ isExportingOption?.text }} Export">
    Export
  </fd-loading-label>
</fd-button>

<mat-menu #menu>
  <button *ngFor="let option of options" mat-menu-item (click)="exportData(option)">
    {{ option.text }}
  </button>
</mat-menu>
