import { Type } from "@angular/core";
import { Data, LoadChildren, Route } from "@angular/router";
import { UserRoles } from "src/app/enums/repository/user-roles.enum";
import { AuthGuard } from "src/app/services/auth/auth-guard.service";
import { DirtyFormCanDeactivateGuard } from "src/app/services/forms/dirty-form-can-deactivate.guard";
import { Primitives } from "../types/primitives.type";

export interface IRouteInfo {
  label: string;
  showInMenu: boolean;
  routerLink?: string;
  externalLink?: string;
  children?: IRouteInfo[];
}

export type RouteInfoData<PathParams extends Primitives[] = [], QueryParams extends {} = {}> = Data & {
  title?: string;
  roleArea?: string;
  roleView?: UserRoles;
  additionalRoles?: UserRoles[];
  showInMenu?: boolean;
  allowAnonymous?: boolean;
  isNestedRoot?: boolean;
  defaultPathParams?: PathParams;
  defaultQueryParams?: QueryParams;
};

export type PathParamsForRoute<T> = T extends RouteInfo<infer PathParams> ? PathParams : never;
export type QueryParamsForRoute<T> = T extends RouteInfo<never, infer QueryParams> ? QueryParams : never;

export class RouteInfo<PathParams extends Primitives[] = [], QueryParams extends {} = {}> implements IRouteInfo {
  routerLink: string;
  children: IRouteInfo[];

  get label() {
    return this.data.title; // for now, just use the page's title
  }

  get showInMenu() {
    return this.data.showInMenu;
  }

  get allowAnonymous() {
    return this.data.allowAnonymous && !this.data.roleArea && !this.data.roleView;
  }

  get additionalRoles() {
    return this.data.additionalRoles ?? [];
  }

  constructor(public parent: RouteInfo, public path: string, public data: RouteInfoData<PathParams, QueryParams>) {
    data.showInMenu = data.showInMenu !== false;
    data.allowAnonymous = data.allowAnonymous === true;
    data.isNestedRoot = data.isNestedRoot === true;
    data.defaultPathParams ??= [] as any;

    this.routerLink = `${parent?.routerLink ?? ""}/${path.split("/:")[0]}`.replace("//", "/");

    if (parent) {
      if (parent.data.isNestedRoot) {
        this.path = `${parent.path}/${path}`;
      }

      if (!parent.children) {
        parent.children = [];
      }

      parent.children.push(this);
    }
  }

  toRoutes(component: Type<any>, options?: { data: Data }): Route[] {
    let fullPath = "";

    return this.path.split("/:?").map(path => {
      if (fullPath) {
        fullPath += "/:" + path;
      } else {
        fullPath = path;
      }

      return {
        path: fullPath,
        data: Object.assign({}, this.data, options?.data),
        component,
        canActivate: this.allowAnonymous ? undefined : [AuthGuard],
        canDeactivate: [DirtyFormCanDeactivateGuard],
      };
    });
  }

  toLazyRoute(loadChildren: LoadChildren, options?: { data: Data }): Route {
    return {
      path: this.path,
      data: Object.assign({}, this.data, options?.data),
      loadChildren,
      canActivate: this.allowAnonymous ? undefined : [AuthGuard],
      canDeactivate: [DirtyFormCanDeactivateGuard],
    };
  }

  buildRouterLink(...params: PathParams): [string, ...PathParams] {
    if (!params.length) {
      params = this.data.defaultPathParams;
    }

    return [this.routerLink, ...params];
  }

  buildQueryParams(queryParams: QueryParams) {
    return queryParams;
  }
}
