import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { AppEnvironment } from "src/app/enums/repository/app-environment.enum";
import { environment } from "src/environments/environment";

@Component({
  selector: "fd-layout-debug",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./fd-layout-debug.component.html",
  styleUrls: ["./fd-layout-debug.component.scss"],
})
export class FdLayoutDebugComponent {
  protected readonly environment = environment;
  protected readonly AppEnvironment = AppEnvironment;
}
