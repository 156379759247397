import { Params } from "@angular/router";
import { prop } from "@rxweb/reactive-form-validators";
import { BreakpointSize, ContainerSize } from "src/app/helpers/types/layouts.type";

export interface LayoutConfigInterface {
  type: "sidenav" | "sidebar" | "basic";
  container: ContainerSize;
  columnWidth: string;
  showBreadcrumbs: boolean;
  showViewLinks: boolean;
  showActions: boolean;
  fixedFooterBreakpoint: BreakpointSize;
}

export class LayoutConfigModel implements LayoutConfigInterface {
  @prop()
  type: LayoutConfigInterface["type"] = "basic";
  @prop()
  container: ContainerSize = "default";
  @prop()
  columnWidth = "100%";
  @prop()
  showBreadcrumbs = false;
  @prop()
  showViewLinks = false;
  @prop()
  showActions = true;
  @prop()
  fixedFooterBreakpoint: BreakpointSize = "md";

  constructor(init?: Partial<LayoutConfigInterface>) {
    Object.assign(this, init);
  }

  toQueryParams() {
    return {
      type: this.type == "basic" ? null : this.type,
      container: this.container === "default" ? null : this.container,
      showBreadcrumbs: this.showBreadcrumbs ? true : null,
      showViewLinks: this.showViewLinks ? true : null,
      showActions: this.showActions ? null : false,
      columnWidth: this.columnWidth === "100%" ? null : this.columnWidth,
      fixedFooterBreakpoint: this.fixedFooterBreakpoint === "md" ? null : this.fixedFooterBreakpoint,
    };
  }

  toOptions() {
    return Object.entries(this.toQueryParams())
      .filter(([key, value]) => value !== null)
      .reduce((options, [key, value]) => {
        options[key] = value;
        return options;
      }, {});
  }

  static fromQueryParams(params: Params) {
    const { type, container, showBreadcrumbs, showViewLinks, showActions, columnWidth, fixedFooterBreakpoint } = params;

    return new LayoutConfigModel({
      type: type ?? "basic",
      container: container || "default",
      showBreadcrumbs: showBreadcrumbs === "true",
      showViewLinks: showViewLinks === "true",
      showActions: showActions !== "false",
      columnWidth: columnWidth ?? "100%",

      fixedFooterBreakpoint: fixedFooterBreakpoint ?? "md",
    });
  }
}
