import { Component, Input, OnChanges, OnDestroy } from "@angular/core";
import { FdSimpleChanges } from "src/app/helpers/types/fd-simple-changes.type";
import { FdTitleService } from "src/app/services/fd-title.service";

/**
 * A component to house meta-data for a page or state of a page.
 * Currently only supports appending a single part to the page's title.
 */
@Component({
  selector: "fd-meta",
  template: "", // no template for now
  standalone: true,
})
export class MetaComponent implements OnChanges, OnDestroy {
  @Input({ required: true }) title: string;
  @Input() truncate: number;

  constructor(private titleService: FdTitleService) {}

  ngOnChanges(changes: FdSimpleChanges<MetaComponent>): void {
    if (changes.title.currentValue !== changes.title.previousValue) {
      this.titleService.resetToCurrentRoute();
      this.titleService.appendToTitle(changes.title.currentValue?.substring(0, this.truncate));
    }
  }

  ngOnDestroy(): void {
    this.titleService.resetToCurrentRoute();
  }
}
