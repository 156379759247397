<div
  [ngClass]="[
    'mat-elevation-z10 fixed-footer',
    breakpoint ?? parent?.config?.fixedFooterBreakpoint
      ? 'd-' + (breakpoint ?? parent?.config?.fixedFooterBreakpoint) + '-none'
      : ''
  ]">
  <div class="{{ container ?? parent?.config?.container | containerClassName }}">
    <ng-content />
  </div>
</div>
<div
  [ngClass]="[
    'toolbar-gap',
    breakpoint ?? parent?.config?.fixedFooterBreakpoint
      ? 'd-' + (breakpoint ?? parent?.config?.fixedFooterBreakpoint) + '-none'
      : ''
  ]"></div>
