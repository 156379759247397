import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { DestroyRef, Injectable, inject } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { AppRoutes } from "src/app/routes";
import { environment } from "src/environments/environment";
import { UserRoles } from "../enums/repository/user-roles.enum";
import { UserDtoInterface } from "../modules/account/models/user-dto.model";
import { AuthService } from "../services/auth/auth.service";

@Injectable()
export abstract class BaseComponent {
  // constants
  protected readonly AppRoutes = AppRoutes;
  protected readonly UserRoles = UserRoles;
  protected readonly environment = environment;

  // destroy ref
  protected readonly destroyRef = inject(DestroyRef);

  // services
  protected readonly authService: AuthService = inject(AuthService);
  protected readonly breakpointObserver: BreakpointObserver = inject(BreakpointObserver);

  protected readonly isHandset$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  protected readonly subscriptions: Subscription[] = [];

  /** Calling this function will make it so that the subscriptions are not automatically unsubscribed for you. */
  protected readonly unregisterDestroyRef = this.destroyRef.onDestroy(() => {
    this.subscriptions.forEach(s => s?.unsubscribe());
  });

  get userContext(): UserDtoInterface {
    return this.authService.currentUser;
  }
}
