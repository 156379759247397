<div
  class="auto-column-grid py-3"
  [style]="'--target-column-width:' + (columnWidth ?? parent?.config?.columnWidth ?? '100%')">
  <ng-container *ngFor="let item of data$ | async; index as index">
    <ng-container [ngTemplateOutlet]="cardDef" [ngTemplateOutletContext]="{ $implicit: item, index }" />
  </ng-container>

  <!-- Blank State-->
  <div *ngIf="dataSource.blankStateMessage" class="surface text-center">
    {{ dataSource.blankStateMessage }}
  </div>
</div>
