import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";

@Component({
  selector: "fd-layout-header-primary",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./fd-layout-header-primary.component.html",
  styleUrls: ["./fd-layout-header-primary.component.scss"],
})
export class FdLayoutHeaderPrimaryComponent {}
