import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { Router } from "@angular/router";
import { IRouteInfo, RouteInfo } from "src/app/helpers/routes/route-info.model";
import { FdRouterService } from "src/app/services/fd-router.service";
import { BaseComponent } from "../../base.component";

@Component({
  selector: "app-nested-nav-item",
  templateUrl: "./nested-nav-item.component.html",
  styleUrls: ["./nested-nav-item.component.scss"],
})
export class NestedNavItemComponent extends BaseComponent implements OnInit {
  @Input() link: IRouteInfo;
  @Input() level: number;
  @Input() drawer: MatSidenav;

  @ViewChild("panel", { read: ElementRef }) panel: ElementRef<HTMLElement>;

  isActive: boolean;
  hasAccess: boolean;

  constructor(protected router: Router, private fdRouter: FdRouterService) {
    super();
  }

  ngOnInit() {
    this.hasAccess = this.userHasAccess();
    this.subscriptions.push(
      this.fdRouter.navigationEnd$.subscribe(() => (this.isActive = this.linkIsActive())),
      this.authService.getLoginStatusEvent().subscribe(() => (this.hasAccess = this.userHasAccess()))
    );
  }

  @HostListener("window:focus") windowFocused() {
    // for some reason, the navigation expansion panels are not showing their content
    // this is a hack to force the expansion panel to close and reopen which is a workaround.
    if (this.panel && this.isActive) {
      this.panel.nativeElement.click();
      setTimeout(() => this.panel.nativeElement.click());
    }
  }

  private userHasAccess() {
    if (this.link instanceof RouteInfo) {
      return (
        this.link.data.allowAnonymous ||
        (this.authService.currentUser?.hasAnyViewRole(this.link.data.roleArea) &&
          this.authService.currentUser?.isInRole(this.link.data.roleView))
      );
    }
    return true;
  }

  private linkIsActive(link?: IRouteInfo) {
    link ??= this.link;

    const isActive = (link: IRouteInfo) => {
      return (
        link.routerLink &&
        this.router.isActive(link.routerLink, {
          paths: "subset",
          queryParams: "subset",
          fragment: "ignored",
          matrixParams: "ignored",
        })
      );
    };

    return (
      isActive(link) ||
      link.children?.some(childLink => {
        if (childLink.children) {
          return childLink.children.length && this.linkIsActive(childLink);
        }

        return isActive(childLink);
      })
    );
  }
}
