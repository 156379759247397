import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, ElementRef, Input, OnChanges, ViewChild } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatTooltipModule } from "@angular/material/tooltip";

@Component({
  selector: "fd-loading-label",
  templateUrl: "./loading-label.component.html",
  styleUrls: ["./loading-label.component.scss"],
  standalone: true,
  imports: [CommonModule, MatIconModule, MatProgressSpinnerModule, MatTooltipModule],
})
export class LoadingLabelComponent implements AfterViewInit, OnChanges {
  @Input() isLoading = false;
  @Input() icon = "";
  @Input() size = 20;
  @Input() append = false;
  @Input() tooltip = true; // allow explicitly disabling the tooltip
  @Input() tooltipText = ""; // allow explicitly setting new tooltip text
  @ViewChild("content") content: ElementRef;

  tooltipContent: string = "";

  ngOnChanges() {
    this.tooltipContent = this.generateTooltip();
  }

  ngAfterViewInit() {
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => {
      this.tooltipContent = this.generateTooltip();
    });
  }

  private generateTooltip() {
    if (!this.tooltip) return "";
    if (this.tooltipText) return this.tooltipText;
    const contentSpan = this.content?.nativeElement as HTMLSpanElement;
    if (contentSpan?.closest(".mat-tooltip-trigger")) return ""; // abort if we are nested inside an existing tooltip
    return contentSpan?.innerText ?? "";
  }
}
