import { Directive, ElementRef, HostListener, OnInit } from "@angular/core";
import { MatSelect } from "@angular/material/select";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "mat-select",
  standalone: true,
})
export class MatSelectDirective {
  constructor(private matSelect: MatSelect, private el: ElementRef) {}

  @HostListener("keydown", ["$event"])
  keydown($event: KeyboardEvent) {
    if (this.el.nativeElement.closest("table[fdKeyboardNavigation] tbody") && $event.code == "Enter") {
      this.matSelect.close();
    }
  }
}
