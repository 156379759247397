<div class="fd-search-input">
  <mat-icon>search</mat-icon>
  <input
    #input
    matInput
    [formControl]="control"
    [placeholder]="placeholder || label"
    [class.has-query]="control.value" />
  <mat-icon class="is-clear" [class.has-query]="control.value" (click)="clear()">close</mat-icon>
</div>
