import { Directive, DoCheck, Input, OnDestroy, OnInit } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { Subscription } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { DBkeys } from "src/app/helpers/constants/db-keys.constants";
import { LocalStoreManager } from "src/app/services/local-store-manager.service";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "mat-paginator",
  standalone: true,
})
export class MatPaginatorDirective implements OnInit, DoCheck, OnDestroy {
  @Input() cacheKey = DBkeys.PAGINATOR_SETTINGS;
  @Input() useCache = true;
  @Input() showFirstLastButtons = true;

  /**
   * 1. Only shows the next page size option after the total count.
   * 2. Only shows the first/last page size buttons if there are more than 2 pages and this.showFirstLastButtons = true.
   */
  @Input() useDynamicSettings = true;

  private readonly pageSizeOptions: number[];
  private readonly subscriptions: Subscription[] = [];

  private previousPageSize = -1;
  private previousLength = -1;

  constructor(private matPaginator: MatPaginator, private localStoreManager: LocalStoreManager) {
    this.pageSizeOptions = this.matPaginator.pageSizeOptions;
  }

  ngOnInit(): void {
    if (this.useCache) {
      const preferredPageSize = this.localStoreManager.getDataObject<number>(this.cacheKey);

      if (preferredPageSize && preferredPageSize !== this.matPaginator.pageSize) {
        this.matPaginator.pageSize = preferredPageSize;
      }

      this.previousPageSize = this.matPaginator.pageSize;

      this.subscriptions.push(
        this.matPaginator.page
          .pipe(
            filter(pageEvent => pageEvent && pageEvent.pageSize !== this.previousPageSize),
            tap(pageEvent => this.cachePreferredPageSize(pageEvent)),
            filter(() => this.useDynamicSettings),
            tap(() => this.setDynamicSettings())
          )
          .subscribe()
      );
    }

    if (this.useDynamicSettings) {
      if (this.matPaginator.length) {
        this.setDynamicSettings();
      }
    }
  }

  ngDoCheck(): void {
    if (this.useDynamicSettings) {
      if (this.previousLength !== this.matPaginator.length || this.previousPageSize !== this.matPaginator.pageSize) {
        this.previousLength = this.matPaginator.length; // there is no event for when the length of the paginator changes, so we need to use ngDoCheck
        this.setDynamicSettings();
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private cachePreferredPageSize(pageEvent: PageEvent) {
    this.localStoreManager.saveData(pageEvent.pageSize.toString(), this.cacheKey);
    this.previousPageSize = pageEvent.pageSize;
  }

  private setDynamicSettings() {
    this.matPaginator.showFirstLastButtons = this.showFirstLastButtons && this.matPaginator.getNumberOfPages() > 2;
    this.matPaginator.pageSizeOptions = this.pageSizeOptions.filter(
      (_, index) => index === 0 || this.pageSizeOptions[index - 1] < this.matPaginator.length
    );
  }
}
