<mat-chip-set aria-label="Views" [class]="'d-none d-' + breakpoint + '-flex'">
  <a
    *ngFor="let link of links; trackBy: trackByItems"
    mat-chip-option
    selectable="false"
    [routerLink]="link.routerLink"
    [queryParams]="link.queryParams"
    [queryParamsHandling]="link.queryParamsHandling ?? queryParamsHandling"
    routerLinkActive
    [routerLinkActiveOptions]="link.routerLinkActiveOptions ?? routerLinkActiveOptions"
    (isActiveChange)="onRouterLinkActive($event, link.label)"
    [highlighted]="active === link.label">
    {{ link.label }}
  </a>
</mat-chip-set>
<div [class]="'d-' + breakpoint + '-none'">
  <button mat-stroked-button [matMenuTriggerFor]="menu">
    <ng-container *ngFor="let link of links; trackBy: trackByItems">
      <span *ngIf="active === link.label">{{ link.label }}</span>
    </ng-container>
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <a
      *ngFor="let link of links; trackBy: trackByItems"
      mat-menu-item
      [routerLink]="link.routerLink"
      [queryParams]="link.queryParams"
      [queryParamsHandling]="link.queryParamsHandling ?? queryParamsHandling">
      {{ link.label }}
    </a>
  </mat-menu>
</div>
