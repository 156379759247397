import { CommonModule } from "@angular/common";
import { Component, ContentChild, Directive, Input, OnDestroy, OnInit, TemplateRef, inject } from "@angular/core";
import { Observable } from "rxjs";
import { FdDataSource } from "src/app/helpers/utilities/data-source";
import { FdLayoutComponent } from "../fd-layout.component";

@Directive({ selector: "[fdCardDef]", standalone: true })
export class FdCardDefDirective {
  templateRef = inject(TemplateRef);
}

@Component({
  selector: "fd-cards",
  standalone: true,
  imports: [CommonModule, FdCardDefDirective],
  templateUrl: "./fd-cards.component.html",
  styleUrls: ["./fd-cards.component.scss"],
})
export class FdCardsComponent implements OnInit, OnDestroy {
  @Input() columnWidth: string;
  @Input() dataSource: FdDataSource<object, unknown>;
  @ContentChild(FdCardDefDirective, { read: TemplateRef }) cardDef: TemplateRef<any>;
  readonly parent? = inject(FdLayoutComponent, { optional: true });

  data$: Observable<unknown[]>;

  ngOnInit() {
    this.data$ = this.dataSource.connect();
  }

  ngOnDestroy(): void {
    this.dataSource.disconnect();
  }
}
