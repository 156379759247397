import { Component, Input, OnInit } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { NavigationEnd, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { Subscription, of } from "rxjs";
import { catchError, filter, map, mergeMap } from "rxjs/operators";
import { AppEnvironment } from "src/app/enums/repository/app-environment.enum";
import { UserRoles } from "src/app/enums/repository/user-roles.enum";
import { DBkeys } from "src/app/helpers/constants/db-keys.constants";
import { RouteInfoData } from "src/app/helpers/routes/route-info.model";
import { AccountHubService } from "src/app/modules/account/services/account-hub.service";
import { AppRoutes } from "src/app/routes";
import { LocalStoreManager } from "src/app/services/local-store-manager.service";
import { environment } from "src/environments/environment";
import { BaseComponent } from "../base.component";

@Component({
  selector: "app-toolbar",
  templateUrl: "./app-toolbar.component.html",
  styleUrls: ["./app-toolbar.component.scss"],
})
export class AppToolbarComponent extends BaseComponent implements OnInit {
  @Input() drawer: MatSidenav;
  @Input() appTitle: string;

  AppEnvironment = AppEnvironment;
  AppRoutes = AppRoutes;

  clientEnvironment = environment.configuration;
  refreshAccountSubscription: Subscription;

  /** Stores all the roles associated with the current route for easy testing. Only applicable in non-production environments. */
  applicableRoles: UserRoles[] = [];

  get fullName(): string {
    return this.authService.currentUser
      ? `${this.authService.currentUser.firstName} ${this.authService.currentUser.lastName}`
      : "";
  }

  get isImpersonatingUser(): boolean {
    return this.authService.currentUser?.impersonatingIdentityId ? true : false;
  }

  get serverEnvironment(): AppEnvironment {
    return AppEnvironment[this.cookieService.get("Environment")]; // the server responds with the initial page load with an Environment cookie
  }

  get showLogin(): boolean {
    return this.router.url !== AppRoutes.login.routerLink && !this.authService.isLoggedIn;
  }

  constructor(
    private accountHubService: AccountHubService,
    private cookieService: CookieService,
    private localStorage: LocalStoreManager,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    if (this.authService.isLoggedIn) {
      this.authService
        .refreshUser()
        .pipe(
          catchError(err => {
            if (err.status === 401) {
              this.signOut();
            }

            return of(false);
          })
        )
        .subscribe();
    }

    this.finalizeInit();

    if (
      this.drawer.opened &&
      this.localStorage.exists(DBkeys.DRAWER_OPEN) &&
      !this.localStorage.getDataObject<boolean>(DBkeys.DRAWER_OPEN)
    ) {
      this.drawer.toggle();
    }

    if (environment.configuration !== AppEnvironment.Production) {
      // don't show the roles picker in production
      this.subscriptions.push(
        this.router.events
          .pipe(
            filter(event => event instanceof NavigationEnd),
            map(_ => this.router.routerState.root),
            map(route => {
              while (route.firstChild) {
                route = route.firstChild;
              }

              return route;
            }),
            mergeMap(route => route.data)
          )
          .subscribe((data: RouteInfoData) => {
            this.applicableRoles = [data.roleView, ...(data.additionalRoles ?? [])].filter(x => x);
          })
      );
    }
  }

  signOut() {
    this.authService.signOut().subscribe();
    this.authService.redirectLogoutUser();
  }

  toggleDrawer() {
    this.drawer.toggle();
    this.localStorage.saveData(this.drawer.opened, DBkeys.DRAWER_OPEN);
  }

  abortImpersonate() {
    this.authService.abortImpersonate().subscribe();
  }

  impersonateRole(reset: boolean, role?: UserRoles) {
    this.authService.impersonateRole(reset, role).subscribe();
  }

  private finalizeInit() {
    this.authService.reLoginDelegate = () => {};

    this.authService.getLoginStatusEvent().subscribe(() => {
      if (this.authService.isLoggedIn) {
        if (!this.refreshAccountSubscription) {
          this.refreshAccountSubscription = this.accountHubService.onRefresh().subscribe();
        }
      } else {
        this.refreshAccountSubscription?.unsubscribe();
        this.refreshAccountSubscription = null;
      }
    });
  }
}
