<mat-form-field appearance="outline" *ngIf="isFdSort">
  <button matPrefix mat-icon-button (click)="toggleSortDirection($event)" matTooltip="Sort Direction" type="button">
    <mat-icon *ngIf="dataSource?.sort.direction === 'asc'">arrow_upward</mat-icon>
    <mat-icon *ngIf="dataSource?.sort.direction === 'desc'">arrow_downward</mat-icon>
  </button>
  <mat-label>{{ label }}</mat-label>
  <mat-select [(ngModel)]="dataSource.sort.active">
    <mat-option *ngFor="let option of options" [value]="option.value">{{ option.label }}</mat-option>
  </mat-select>
</mat-form-field>
