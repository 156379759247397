import { CommonModule } from "@angular/common";
import {
  AfterContentInit,
  Component,
  ContentChildren,
  DestroyRef,
  Directive,
  HostListener,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  inject,
} from "@angular/core";
import { MatDrawer, MatSidenavModule } from "@angular/material/sidenav";

import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ContainerSize } from "src/app/helpers/types/layouts.type";
import { LayoutConfigModel } from "src/app/modules/framework/models/layout-config.model";
import { fadeInOut } from "src/app/services/animations/fade-in-out.animation";
import { ContainerClassNamePipe } from "../../pipes/container-class-name.pipe";
import { FdSpinnerComponent } from "../fd-spinner/fd-spinner.component";

@Directive({
  selector: "[fdLayoutToggle]",
  standalone: true,
})
export class FdLayoutToggleDirective {
  layout?: FdLayoutComponent;

  @HostListener("click")
  onClick() {
    this.layout?.drawer?.toggle();
  }
}

@Component({
  selector: "fd-layout",
  standalone: true,
  imports: [
    CommonModule,
    MatSidenavModule,
    FdSpinnerComponent,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    ContainerClassNamePipe,
  ],
  templateUrl: "./fd-layout.component.html",
  styleUrls: ["./fd-layout.component.scss"],
  animations: [fadeInOut],
})
export class FdLayoutComponent implements OnInit, AfterContentInit {
  @ViewChild("drawer") drawer: MatDrawer;
  @ContentChildren(FdLayoutToggleDirective, { descendants: true }) toggles: QueryList<FdLayoutToggleDirective>;
  /** Title displayed in the header */
  @Input() title: string;
  /** Set a container size. This is ignored if config is used */
  @Input() container: ContainerSize;
  /** Provide a LayoutConfigModel instance to configure the layout */
  @Input() config: LayoutConfigModel;
  /** Hide the UI elements and show a spinner */
  @Input() showSpinner = false;
  private readonly destroyRef = inject(DestroyRef);

  ngOnInit() {
    this.config ??= new LayoutConfigModel({ container: this.container });
  }

  ngAfterContentInit() {
    this.setDrawerOnToggles();
    this.toggles.changes.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.setDrawerOnToggles();
    });
  }

  private setDrawerOnToggles() {
    this.toggles.forEach(directive => {
      directive.layout = this;
    });
  }
}
