<mat-chip-listbox>
  <mat-chip
    (click)="$event.preventDefault()"
    [matBadge]="serverEnvironment !== undefined && clientEnvironment !== serverEnvironment ? '!' : ''"
    matBadgeColor="warn"
    [matMenuTriggerFor]="environmentUrls"
    [matTooltip]="
      serverEnvironment !== undefined && clientEnvironment !== serverEnvironment
        ? 'Client environment is ' + AppEnvironment[clientEnvironment]
        : ''
    ">
    <span class="d-none d-md-block">{{ AppEnvironment[appEnvironment] }}</span>
    <span class="d-md-none">{{ AppEnvironment[appEnvironment][0] }}</span>
  </mat-chip>
</mat-chip-listbox>

<mat-menu #environmentUrls>
  <a
    mat-menu-item
    target="testing"
    [href]="environment.testingUrl"
    *ngIf="clientEnvironment !== AppEnvironment.Testing">
    Testing Site
    <mat-icon> open_in_new </mat-icon>
  </a>
  <a
    mat-menu-item
    target="production"
    [href]="environment.productionUrl"
    *ngIf="clientEnvironment !== AppEnvironment.Production && clientEnvironment !== AppEnvironment.Beta">
    Production Site
    <mat-icon> open_in_new </mat-icon>
  </a>
</mat-menu>
