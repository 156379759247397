import { MatFormFieldDefaultOptions } from "@angular/material/form-field";
import { MatPaginatorDefaultOptions } from "@angular/material/paginator";
import { PasswordValidation } from "@rxweb/reactive-form-validators/models";
import { AppEnvironment } from "../app/enums/repository/app-environment.enum";

export abstract class IEnvironment {
  configuration: AppEnvironment;

  mvcPortalBaseUrl: string;
  jiraBaseUrl: string;
  passwordRequirements: PasswordValidation;
  passwordRequirementMessages: string[];
  onlineStatusNotifications: {
    enabled: boolean;
    connectionRestored: string;
    connectionLost: string;
  };
  matPaginatorDefaultOptions: MatPaginatorDefaultOptions;
  matFormFieldDefaultOptions: MatFormFieldDefaultOptions;
  testingUrl: string;
  productionUrl: string;
}

export const defaultEnvironment: IEnvironment = {
  configuration: AppEnvironment.Development,
  testingUrl: "https://portal2-dev.freedomdev.com",
  productionUrl: "https://portal2.freedomdev.com",
  mvcPortalBaseUrl: "https://portal-dev.freedomdev.com",
  jiraBaseUrl: "https://freedomdev-sandbox-867.atlassian.net",
  passwordRequirements: {
    minLength: 6,
    digit: true,
    upperCase: true,
    lowerCase: true,
  },
  passwordRequirementMessages: [
    "Must be at least 6 characters in length.",
    "Must contain an uppercase letter.",
    "Must contain a lowercase letter.",
    "Must contain a number.",
  ],
  onlineStatusNotifications: {
    enabled: true, // When true, notifications will be shown when network connectivity is lost or restored
    connectionRestored: "You are now back online!",
    connectionLost: "You are offline.",
  },
  matPaginatorDefaultOptions: {
    formFieldAppearance: "fill",
    hidePageSize: false,
    pageSize: 25,
    pageSizeOptions: [5, 25, 100],
    showFirstLastButtons: true,
  },
  matFormFieldDefaultOptions: {
    appearance: "fill",
  },
};
