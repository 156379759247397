<div class="row flex-column g-0 h-100">
  <div class="app-navigation-header">
    <a routerLink="/">
      <img src="assets/images/logo-color.png" alt="{{ appTitle }}" />
    </a>
    <mat-divider />
  </div>

  <mat-nav-list class="overflow-y-scroll col">
    <!-- Recursive list of navigation items with potential nesting-->
    <app-nested-nav-item *ngFor="let link of root.children" [level]="0" [link]="link" [drawer]="drawer" />
  </mat-nav-list>
</div>
