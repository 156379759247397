import { EventEmitter } from "@angular/core";
import { Sort, SortDirection } from "@angular/material/sort";
import { Option } from "../types/options.type";

export class FdSort implements Sort {
  private _active: string;
  private _direction: SortDirection = "asc";
  private _options: Option[];
  readonly change: EventEmitter<Sort> = new EventEmitter<Sort>();

  get options() {
    return this._options;
  }

  set options(value: Option[]) {
    this._options = value;
    this._update();
  }

  get active(): string {
    return this._active;
  }

  set active(value: string) {
    this._active = value;
    this._update();
  }

  get direction(): SortDirection {
    return this._direction;
  }

  set direction(value: SortDirection) {
    this._direction = value;
    this._update();
  }

  constructor(options: Option[] = [], direction: SortDirection = "asc", active: string | undefined) {
    this._options = options;
    this._direction = direction;
    this._active = active ?? options?.[0]?.value;
  }

  _update() {
    this.change.emit({ active: this.active, direction: this.direction });
  }
}
