import { Component, Input } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { AppRoutes } from "src/app/routes";
import { BaseComponent } from "../base.component";

@Component({
  selector: "app-navigation",
  templateUrl: "./app-navigation.component.html",
  styleUrls: ["./app-navigation.component.scss"],
})
export class AppNavigationComponent extends BaseComponent {
  @Input() appTitle: string;
  @Input() drawer: MatSidenav;

  root = AppRoutes.root;
}
