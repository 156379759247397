import { CommonModule } from "@angular/common";
import { Component, HostBinding } from "@angular/core";

@Component({
  selector: "fd-layout-header-secondary",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./fd-layout-header-secondary.component.html",
  styleUrls: ["./fd-layout-header-secondary.component.scss"],
})
export class FdLayoutHeaderSecondaryComponent {
  @HostBinding("class") class = "flex-spaced justify-content-between align-items-center hide-when-empty";
}
