import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, Input } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectModule } from "@angular/material/select";
import { FdDataSource } from "src/app/helpers/utilities/data-source";
import { FdSort } from "src/app/helpers/utilities/sort";

@Component({
  selector: "fd-sort-select",
  templateUrl: "./fd-sort-select.component.html",
  styleUrls: ["./fd-sort-select.component.scss"],
  standalone: true,
  imports: [CommonModule, FormsModule, MatFormFieldModule, MatSelectModule, MatButtonModule, MatIconModule],
})
export class FdSortSelectComponent implements AfterViewInit {
  @Input() dataSource?: FdDataSource<any, unknown>;
  @Input() label = "Sort";

  get isFdSort() {
    return this.dataSource.sort instanceof FdSort;
  }
  
  get options() {
    return this.isFdSort ? (this.dataSource.sort as FdSort).options : []
  }
    
  ngAfterViewInit() {
    if (!this.isFdSort) {
      console.warn('fd-sort-select is only intended for use with data sources using FdSort (not MatSort)'); 
    }
  }

  toggleSortDirection(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.dataSource.sort.direction = this.dataSource.sort.direction === "asc" ? "desc" : "asc";
  }
}
