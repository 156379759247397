/** Client-side model of the Server's AppEnvironment enum. */
export enum AppEnvironment {
  /** This value represents local development environments. */
  Development,

  Testing,

  /** This value allows us to override production settings for https://fdangular.freedomdev.com */
  Beta,

  Production,
}
