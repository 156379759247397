import { Directive, HostListener, Input, OnDestroy, OnInit, Optional } from "@angular/core";
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import { Subscription } from "rxjs";
import { FormService } from "src/app/services/forms/form.service";

@Directive({
  /* eslint-disable */
  selector: "form,[form-group]",
  standalone: true,
})
export class DirtyFormDirective implements OnInit, OnDestroy {
  private isDirty = false;
  private initialized = false;
  private dirtySubscription: Subscription;

  private _dirtyIgnore: boolean = false;
  @Input() set dirtyIgnore(value: boolean) {
    const temp = this._dirtyIgnore;

    this._dirtyIgnore = value;

    // we need to dynamically initialize / dispose of the dirty logic as the dirtyIgnore flag is changed

    if (temp && !value) {
      this.stopListening(true);
    }

    if (!temp && value && this.initialized) {
      this.startListening();
    }
  }

  get enabled() {
    return !this._dirtyIgnore && !!this.form;
  }

  constructor(
    private formService: FormService,
    @Optional() private form: ControlContainer,
    @Optional() private formGroup: FormGroupDirective
  ) {}

  ngOnInit(): void {
    this.initialized = true;

    this.reactifyFormStatusActions();

    this.startListening();
  }

  ngOnDestroy(): void {
    this.stopListening(this.enabled);
  }

  @HostListener("window:beforeunload", ["$event"])
  alertUserBeforeExternalNavigation($event) {
    if (this.isDirty && this.enabled) {
      // most browsers will ignore this message, but it will trigger the default confirmation dialog regardless
      return ($event.returnValue = "Are you sure you want to leave? You may lose your changes.");
    }
  }

  private checkFormStatus() {
    if (this.enabled && this.isDirty !== this.form.dirty) {
      this.formService.setDirtyForm(this.form.dirty);
      this.isDirty = this.form.dirty;
    }
  }

  private startListening() {
    if (!this.enabled) {
      return;
    }

    this.dirtySubscription = this.form.valueChanges.subscribe(() => this.checkFormStatus());

    if (this.form.dirty) {
      // the form started off as dirty so we need to update the state
      this.formService.addDirtyForm();
      this.isDirty = true;
    }
  }

  private stopListening(removeDirty: boolean) {
    this.dirtySubscription?.unsubscribe();
    this.dirtySubscription = null;

    if (removeDirty && this.form.dirty) {
      // form was still dirty, but the directive is being disposed of anyway
      this.formService.removeDirtyForm();
      this.isDirty = false;
    }
  }

  /**
   * There is no built in way to get events for when a caller manually calls markAsPristine or dirty.
   * This method uses the JS language features to overload those functions in order to gain that ability.
   */
  private reactifyFormStatusActions() {
    if (this.formGroup?.form) {
      const markAsPristine = this.formGroup.form.markAsPristine;
      const markAsDirty = this.formGroup.form.markAsDirty;

      const self = this;

      this.formGroup.form.markAsPristine = function (opts) {
        markAsPristine.apply(self.formGroup.form, opts);
        self.checkFormStatus();
      };

      this.formGroup.form.markAsDirty = function (opts) {
        markAsDirty.apply(self.formGroup.form, opts);
        self.checkFormStatus();
      };
    }
  }
}
