import { CommonModule } from "@angular/common";
import { Component, Input, inject } from "@angular/core";
import { BreakpointSize, ContainerSize } from "src/app/helpers/types/layouts.type";
import { ContainerClassNamePipe } from "../../../pipes/container-class-name.pipe";
import { FdLayoutComponent } from "../fd-layout.component";

@Component({
  selector: "fd-layout-fixed-footer",
  standalone: true,
  imports: [CommonModule, ContainerClassNamePipe],
  templateUrl: "./fd-layout-fixed-footer.component.html",
  styleUrls: ["./fd-layout-fixed-footer.component.scss"],
})
export class FdLayoutFixedFooterComponent {
  @Input() breakpoint: BreakpointSize;
  @Input() container: ContainerSize;
  readonly parent? = inject(FdLayoutComponent, { optional: true });
}
