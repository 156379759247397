import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { DestroyRef, Injectable, inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Observable, Subject, Subscription, fromEvent } from "rxjs";
import { filter, map, shareReplay, tap } from "rxjs/operators";
import { AppRoutes } from "src/app/routes";
import { environment } from "src/environments/environment";
import { UserRoles } from "../enums/repository/user-roles.enum";
import { UserDtoInterface } from "../modules/account/models/user-dto.model";
import { AuthService } from "../services/auth/auth.service";
import { BackdropClass, FdDialog } from "../services/fd-dialog.service";
import { FormService } from "../services/forms/form.service";
import { DirtyFormDialogComponent } from "./dirty-form-dialog/dirty-form-dialog.component";
import { FdSnackBar } from "../services/fd-snack-bar.service";

@Injectable()
export abstract class BaseDialogComponent<Input = never, Output = never> {
  //#region BaseComponent Functionality
  /*
   * HACK: Extending BaseComponent will cause this issue: https://github.com/angular/angular-cli/issues/15077.
   * TODO: Figure out how to resolve that issue, extend BaseComponent, and remove this region.
   */

  // constants
  protected readonly AppRoutes = AppRoutes;
  protected readonly UserRoles = UserRoles;
  protected readonly environment = environment;

  // destroy ref
  protected readonly destroyRef = inject(DestroyRef);

  // services
  protected readonly authService = inject(AuthService);
  protected readonly breakpointObserver = inject(BreakpointObserver);
  protected readonly snackBar = inject(FdSnackBar);

  protected readonly isHandset$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  protected readonly subscriptions: Subscription[] = [];

  /** Calling this function will make it so that the subscriptions are not automatically unsubscribed for you. */
  protected readonly unregisterDestroyRef = this.destroyRef.onDestroy(() => {
    this.subscriptions.forEach(s => s?.unsubscribe());
  });

  get userContext(): UserDtoInterface {
    return this.authService.currentUser;
  }
  //#endregion

  // define abstract properties first so that we can use them when setting other props

  /**
   * The backdrop class to apply to this dialog. If your dialog "jumps" around in size, then you should set a backdrop class to constrain the dialog to a specific size.
   * Otherwise, if your dialog doesn't shift in size, you can just pass "intrinsic" to allow the dialog to be it's intrinsic size.
   */
  protected abstract get backdropClass(): BackdropClass | BackdropClass[];

  // don't let consumers hook into the dialogRef. We want to control as much of the dialog lifecycle as possible.
  private readonly dialogRef: MatDialogRef<BaseDialogComponent<Input, Output>> = (() => {
    const dialogRef = inject(MatDialogRef<BaseDialogComponent<Input, Output>>);

    // note: we cannot use the constructor to access backdropClass since it is an abstract property
    const backdropClass = [
      "cdk-overlay-dark-backdrop", // always add the dark backdrop as per our best practices
      ...(Array.isArray(this.backdropClass) ? this.backdropClass : [this.backdropClass]),
    ].filter(x => !!x && x !== "intrinsic"); // don't add "intrinsic" since it just identifies the default behavior

    // note: we can only access the backdrop by using the document API from this context.
    const backdrop: HTMLElement = document.querySelector(".cdk-overlay-backdrop");
    backdrop.classList.add(...backdropClass);

    if (!dialogRef.disableClose) {
      this.subscriptions.push(
        fromEvent(backdrop, "click").subscribe(() => this.close()),
        fromEvent(window, "keydown")
          .pipe(filter((e: KeyboardEvent) => e.key === "Escape"))
          .subscribe(() => this.close())
      );
    }

    dialogRef.disableClose = true;

    return dialogRef;
  })();

  protected readonly input: Input = inject(MAT_DIALOG_DATA);
  protected readonly formService = inject(FormService);
  protected readonly dialog = inject(FdDialog);
  protected disableDirtyFormChecking = false;

  /** If an output value is truthy, dirty form checking is disabled as we assume the user submitted/saved through this flow. */
  protected close(output?: Output): boolean | Observable<boolean> {
    if (!this.disableDirtyFormChecking && this.formService.isDirty && !output) {
      const closeSubject = new Subject<boolean>();

      this.dialog
        .open(DirtyFormDialogComponent, {
          fragment: null, // not applicable
        })
        .beforeClosed()
        .pipe(
          tap(deactivate => {
            closeSubject.next(deactivate);
            closeSubject.complete();
            if (deactivate) {
              this.dialogRef.close(output);
            }
          })
        )
        .subscribe();

      return closeSubject.asObservable();
    }

    this.dialogRef.close(output);
    return true;
  }

  protected closeItemNotFound() {
    this.snackBar.openWarn("Item not found.");
    this.dialogRef.close();
    return false;
  }
}
