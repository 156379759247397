import { Primitives } from "../types/primitives.type";
import { IRouteInfo, RouteInfoData } from "./route-info.model";

export class ExternalRouteInfo<PathParams extends Primitives[] = [], QueryParams extends {} = {}>
  implements IRouteInfo
{
  get showInMenu() {
    return this.data.showInMenu;
  }

  get allowAnonymous() {
    return this.data.allowAnonymous && !this.data.roleArea && !this.data.roleView;
  }

  constructor(
    public parent: IRouteInfo,
    public label: string,
    public externalLink: string,
    public data: RouteInfoData<PathParams, QueryParams>
  ) {
    data.showInMenu = data.showInMenu === true;
    if (data.newWindow) {
      data.target = "_blank";
    }

    if (parent) {
      if (!parent.children) {
        parent.children = [];
      }

      parent.children.push(this);
    }
  }
}
