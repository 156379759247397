import { NgModule } from "@angular/core";
import { DataFooterComponent } from "./components/data-footer/data-footer.component";
import { FdCardDefDirective, FdCardsComponent } from "./components/fd-layout/fd-cards/fd-cards.component";
import { FdLayoutBreadcrumbsComponent } from "./components/fd-layout/fd-layout-breadcrumbs/fd-layout-breadcrumbs.component";
import { FdLayoutContentComponent } from "./components/fd-layout/fd-layout-content/fd-layout-content.component";
import { FdLayoutDebugComponent } from "./components/fd-layout/fd-layout-debug/fd-layout-debug.component";
import { FdLayoutFixedFooterComponent } from "./components/fd-layout/fd-layout-fixed-footer/fd-layout-fixed-footer.component";
import { FdLayoutHeaderPrimaryComponent } from "./components/fd-layout/fd-layout-header-primary/fd-layout-header-primary.component";
import { FdLayoutHeaderSecondaryComponent } from "./components/fd-layout/fd-layout-header-secondary/fd-layout-header-secondary.component";
import { FdLayoutSidebarComponent } from "./components/fd-layout/fd-layout-sidebar/fd-layout-sidebar.component";
import { FdLayoutSidenavComponent } from "./components/fd-layout/fd-layout-sidenav/fd-layout-sidenav.component";
import { FdLayoutTitleComponent } from "./components/fd-layout/fd-layout-title/fd-layout-title.component";
import { FdLayoutComponent, FdLayoutToggleDirective } from "./components/fd-layout/fd-layout.component";
import { FdSortSelectComponent } from "./components/fd-sort-select/fd-sort-select.component";
import { FdSpinnerComponent } from "./components/fd-spinner/fd-spinner.component";
import { FdViewLinksComponent } from "./components/fd-view-links/fd-view-links.component";
import { MetaComponent } from "./components/meta/meta.component";
import { SearchFormFieldComponent } from "./components/search-form-field/search-form-field.component";
import { SearchInputComponent } from "./components/search-input/search-input.component";
import { SplitButtonComponent } from "./components/split-button/split-button.component";
import { TableExporterComponent } from "./components/table-exporter/table-exporter.component";
import { AutofocusDirective } from "./directives/autofocus.directive";
import { DirtyFormDirective } from "./directives/dirty-form.directive";
import { FormControlSyncDirective } from "./directives/form-control-sync.directive";
import { InputDirective } from "./directives/input.directive";
import { MatCellDirective } from "./directives/mat-cell.directive";
import { MatDialogCloseUnsupportedDirective } from "./directives/mat-dialog-close-unsupported.directive";
import { MatPaginatorDirective } from "./directives/mat-paginator.directive";
import { MatSelectDirective } from "./directives/mat-select.directive";
import { TypeSafeMatCellDef } from "./directives/type-safe-mat-cell-def.directive";
import { ContainerClassNamePipe } from "./pipes/container-class-name.pipe";

const global = [
  AutofocusDirective,
  ContainerClassNamePipe,
  DataFooterComponent,
  DirtyFormDirective,
  FdCardDefDirective,
  FdCardsComponent,
  FdLayoutBreadcrumbsComponent,
  FdLayoutComponent,
  FdLayoutContentComponent,
  FdLayoutDebugComponent,
  FdLayoutFixedFooterComponent,
  FdLayoutHeaderPrimaryComponent,
  FdLayoutHeaderSecondaryComponent,
  FdLayoutSidebarComponent,
  FdLayoutSidenavComponent,
  FdLayoutTitleComponent,
  FdLayoutToggleDirective,
  FdSortSelectComponent,
  FdViewLinksComponent,
  FormControlSyncDirective,
  InputDirective,
  MatCellDirective,
  MatDialogCloseUnsupportedDirective,
  MatPaginatorDirective,
  MatSelectDirective,
  SearchFormFieldComponent,
  SearchInputComponent,
  SplitButtonComponent,
  TableExporterComponent,
  TypeSafeMatCellDef,
  FdSpinnerComponent,
  MetaComponent,
];

/**
 * All directives, components, and pipes that should be applied globally because they either:
 *   a) Should automatically augment built-in HTML elements always.
 *   b) Are used all the time so that it makes to just include them automatically.
 *
 * This module still needs to be imported in each individual module or standalone component for it to take effect.
 *
 * Any components that may be used, but don't fit the above description should not be included here.
 */
@NgModule({
  imports: global,
  exports: global,
})
export class SharedModule {}
