import { environment } from "src/environments/environment";
import { AppEnvironment } from "./enums/repository/app-environment.enum";
import { UserRoles } from "./enums/repository/user-roles.enum";
import { ExternalRouteInfo } from "./helpers/routes/external-route-info.model";
import { RouteInfo } from "./helpers/routes/route-info.model";

export class AppRoutes {
  public static readonly root = new RouteInfo(null, "", { title: "", allowAnonymous: true });

  public static readonly login = new RouteInfo(AppRoutes.root, "login", {
    title: "Login",
    showInMenu: false,
    allowAnonymous: true,
  });
  public static readonly home = new RouteInfo(AppRoutes.root, "home", { title: "Home", showInMenu: false });
  public static readonly notAuthorized = new RouteInfo(AppRoutes.root, "not-authorized", {
    title: "Not Authorized",
    showInMenu: false,
    allowAnonymous: true,
  });

  public static timeTrackerCustom = new ExternalRouteInfo(
    AppRoutes.root,
    "Time Tracker",
    `${environment.mvcPortalBaseUrl}/TimeTracker/DayView`,
    { showInMenu: true, roleView: UserRoles.TimeTracker_DayView_View }
  );
  public static peoplePlannerCustom = new RouteInfo(AppRoutes.root, "time-tracker/people-planner", {
    title: "People Planner",
    roleView: UserRoles.TimeTracker_PeoplePlanner_View,
  });

  public static projects = new RouteInfo(AppRoutes.root, "projects", {
    title: "Projects",
    showInMenu: true,
    roleView: UserRoles.Project_Projects_View,
  });

  public static dashboards = new ExternalRouteInfo(
    AppRoutes.root,
    "Dashboards",
    `${environment.mvcPortalBaseUrl}/Dashboards/Dashboard`,
    { showInMenu: true, roleView: UserRoles.Dashboards_Dashboard_View }
  );

  public static timeTrackerRoot = new RouteInfo(AppRoutes.root, "time-tracker", {
    title: "Time Tracker",
    roleArea: UserRoles.TimeTracker,
    showInMenu: false,
  });

  public static timeTracker = {
    peoplePlanner: new RouteInfo(AppRoutes.timeTrackerRoot, "people-planner", {
      title: "People Planner",
      roleView: UserRoles.TimeTracker_PeoplePlanner_View,
    }),
  };

  public static billingRoot = new RouteInfo(AppRoutes.root, "billing", {
    title: "Billing",
    roleArea: UserRoles.Billing,
  });
  public static billing = {
    billingSummary: new ExternalRouteInfo(
      AppRoutes.billingRoot,
      "Billing Summary",
      `${environment.mvcPortalBaseUrl}/Billing/BillingSummary`,
      { showInMenu: true, roleView: UserRoles.Reports_Billable_View }
    ),
    spendingSummary: new ExternalRouteInfo(
      AppRoutes.billingRoot,
      "Spending Summary",
      `${environment.mvcPortalBaseUrl}/Billing/SpendingSummary`,
      { showInMenu: true, roleView: UserRoles.Billing_Summary_View }
    ),
  };

  public static salesRoot = new RouteInfo(AppRoutes.root, "sales", { title: "Sales", roleArea: UserRoles.Sales });
  public static sales = {
    estimates: new RouteInfo(AppRoutes.salesRoot, "estimates", {
      title: "Client Estimates",
      roleView: UserRoles.Sales_Estimate_View,
    }),
  };

  public static stewardshipRoot = new RouteInfo(AppRoutes.root, "stewardship", {
    title: "Stewardship",
  });
  public static stewardship = {
    kivaMap: new RouteInfo(AppRoutes.stewardshipRoot, "kiva-map", {
      title: "KIVA Map",
    }),
    kivaStats: new RouteInfo(AppRoutes.stewardshipRoot, "kiva-stats", {
      title: "KIVA Stats",
    }),
    manageKiva: new RouteInfo(AppRoutes.stewardshipRoot, "manage-kiva", {
      title: "Manage KIVA",
      roleView: UserRoles.Stewardship_Manage_View,
    }),
  };

  public static reportsRoot = new RouteInfo(AppRoutes.root, "reports", {
    title: "Reports",
    roleArea: UserRoles.Reports,
  });
  public static reports = {
    billablePercent: new RouteInfo(AppRoutes.reportsRoot, "billable-report", {
      title: "Billable %",
      roleView: UserRoles.Reports_Billable_View,
    }),

    timeActivityByProject: new RouteInfo(AppRoutes.reportsRoot, "timeactivity-by-project-report/:?id", {
      title: "Time Activity by Project",
      roleView: UserRoles.Reports_Billable_View,
    }),

    timeActivityByClient: new RouteInfo(AppRoutes.reportsRoot, "timeactivity-by-client-report/:?id", {
      title: "Time Activity by Client",
      roleView: UserRoles.Reports_TimeActivityByClient_View,
    }),
    timeActivityByPerson: new RouteInfo(AppRoutes.reportsRoot, "timeactivity-by-person-report/:?id", {
      title: "Time Activity by Person",
      roleView: UserRoles.Reports_TimeActivityByPerson_View,
    }),

    writeOffReport: new RouteInfo(AppRoutes.reportsRoot, "write-off-report", {
      title: "Write Off Report",
      roleView: UserRoles.Reports_WriteOffReport_View,
    }),
  };

  // #region Admin Module
  public static adminRoot = new RouteInfo(AppRoutes.root, "admin", { title: "Admin", roleArea: UserRoles.Admin });
  public static admin = {
    categories: new ExternalRouteInfo(
      AppRoutes.adminRoot,
      "Categories",
      `${environment.mvcPortalBaseUrl}/Admin/Categories`,
      { showInMenu: true, roleView: UserRoles.Admin_Categories_View }
    ),
    clients: new ExternalRouteInfo(AppRoutes.adminRoot, "Clients", `${environment.mvcPortalBaseUrl}/Admin/Clients`, {
      showInMenu: true,
      roleView: UserRoles.Admin_Clients_View,
    }),

    qbCodes: new RouteInfo(AppRoutes.adminRoot, "qb-codes", {
      title: "QuickBook Codes",
      roleView: UserRoles.Admin_QBCodes_View,
    }),

    users: new ExternalRouteInfo(AppRoutes.adminRoot, "Team", `${environment.mvcPortalBaseUrl}/Admin/Team`, {
      showInMenu: true,
      roleView: UserRoles.Admin_Team_View,
    }),
    securityGroups: new RouteInfo(AppRoutes.adminRoot, "security-groups", {
      title: "Security Groups",
      roleView: UserRoles.Admin_SecurityGroups_View,
    }),
    holidayGroups: new RouteInfo(AppRoutes.adminRoot, "holiday-groups", {
      title: "Holidays",
      roleView: UserRoles.TimeTracker_HolidayGroups_View,
    }),
  };
  // #endregion

  public static helpfulLinksRoot = new RouteInfo(AppRoutes.root, "helpfulLinks", {
    title: "Helpful Links",
    allowAnonymous: true,
  });
  public static helpfulLinks = {
    reportABug: new ExternalRouteInfo(
      AppRoutes.helpfulLinksRoot,
      "Report a Bug",
      `https://forms.office.com/r/4bJzjp6378`,
      { showInMenu: true, allowAnonymous: true }
    ),
    submitASuggestion: new ExternalRouteInfo(
      AppRoutes.helpfulLinksRoot,
      "Submit a Suggestion",
      `https://forms.office.com/r/j97w4y2FrN`,
      { showInMenu: true, allowAnonymous: true }
    ),
    teamPlaybook: new ExternalRouteInfo(
      AppRoutes.helpfulLinksRoot,
      "Team Playbook",
      `https://freedomdevus.sharepoint.com/Team/Forms/AllItems.aspx?RootFolder=%2FTeam%2FPlaybook&FolderCTID=0x0120007819347027A5594FAA063FFDDF2A7899`,
      { showInMenu: true, allowAnonymous: true }
    ),
  };

  // #region Account Module
  public static accountRoot = new RouteInfo(null, "account", {
    title: "Account",
    showInMenu: false,
    allowAnonymous: true,
  });
  public static account = {
    registration: new RouteInfo(AppRoutes.accountRoot, "registration", {
      title: "Registration",
      allowAnonymous: true,
    }),
    externalLoginCallback: new RouteInfo(AppRoutes.accountRoot, "external-login-callback", {
      title: "Logging you in...",
      allowAnonymous: true,
    }),
    confirmEmail: new RouteInfo(AppRoutes.accountRoot, "confirm-email", {
      title: "Confirm Email",
      allowAnonymous: true,
    }),
    forgotPassword: new RouteInfo(AppRoutes.accountRoot, "forgot-password", {
      title: "Forgot Password",
      allowAnonymous: true,
    }),
    resetPassword: new RouteInfo(AppRoutes.accountRoot, "reset-password", {
      title: "Reset Password",
      allowAnonymous: true,
    }),
    profile: new RouteInfo(AppRoutes.accountRoot, "profile", { title: "Profile" }),
  };
  // #endregion

  // #region Framework Module
  public static frameworkRoot = new RouteInfo(AppRoutes.root, "framework", {
    title: "Framework",
    showInMenu: environment.configuration !== AppEnvironment.Production,
  });
  public static frameworkSwaggerRoot = new ExternalRouteInfo(AppRoutes.frameworkRoot, "Swagger", "/Swagger", {
    showInMenu: environment.configuration === AppEnvironment.Development,
    target: "swagger",
  });
  public static frameworkRinRoot = new ExternalRouteInfo(AppRoutes.frameworkRoot, "Request Inspector (Rin)", "/rin", {
    showInMenu: environment.configuration === AppEnvironment.Development,
    target: "rin",
  });
  public static frameworkCoreRoot = new RouteInfo(AppRoutes.frameworkRoot, "core", {
    title: "Core",
    isNestedRoot: true,
  });
  public static frameworkControlsRoot = new RouteInfo(AppRoutes.frameworkRoot, "controls", {
    title: "Form Controls",
    isNestedRoot: true,
  });
  public static frameworkFilesRoot = new RouteInfo(AppRoutes.frameworkRoot, "files", {
    title: "Files",
    isNestedRoot: true,
  });
  public static frameworkWidgetsRoot = new RouteInfo(AppRoutes.frameworkRoot, "widgets", {
    title: "Widgets",
    isNestedRoot: true,
  });
  public static frameworkLayoutsRoot = new RouteInfo(AppRoutes.frameworkRoot, "layouts", {
    title: "Layouts",
    isNestedRoot: true,
  });
  public static framework = {
    // core
    about: new RouteInfo(AppRoutes.frameworkCoreRoot, "about", { title: "About" }),
    email: new RouteInfo(AppRoutes.frameworkCoreRoot, "email", { title: "Email" }),
    errorHandling: new RouteInfo(AppRoutes.frameworkCoreRoot, "error-handling", { title: "Error Handling" }),
    dateTime: new RouteInfo(AppRoutes.frameworkCoreRoot, "date-time", { title: "Date & Time" }),
    form: new RouteInfo(AppRoutes.frameworkCoreRoot, "form", { title: "Form" }),
    globalLoadingIndicator: new RouteInfo(AppRoutes.frameworkCoreRoot, "global-loading-indicator", {
      title: "Global Loading Indicator",
    }),
    invalidModelState: new RouteInfo(AppRoutes.frameworkCoreRoot, "invalid-model-state", {
      title: "Invalid Model State ",
    }),
    pipes: new RouteInfo(AppRoutes.frameworkCoreRoot, "pipes", { title: "Pipes" }),
    prototyping: new RouteInfo(AppRoutes.frameworkCoreRoot, "prototyping", { title: "Prototyping" }),
    routing: new RouteInfo<[number, string, boolean?], { age?: number; grade?: string }>(
      AppRoutes.frameworkCoreRoot,
      "routing/:id/:name/:?flag",
      {
        title: "Routing",
        defaultPathParams: [0, "_"],
        defaultQueryParams: { age: 0 },
      }
    ),
    cache: new RouteInfo<["server" | "client"]>(AppRoutes.frameworkCoreRoot, "cache/:?view", {
      title: "Cache",
      defaultPathParams: ["server"],
    }),

    files: new RouteInfo<["ico" | "csv" | "xlsx" | "pdf"]>(AppRoutes.frameworkCoreRoot, "download-file/:?fileType", {
      title: "Files",
      defaultPathParams: ["ico"],
    }),

    // form controls
    autocomplete: new RouteInfo(AppRoutes.frameworkControlsRoot, "autocomplete", { title: "Autocomplete" }),
    checkbox: new RouteInfo(AppRoutes.frameworkControlsRoot, "checkbox", { title: "Checkbox" }),
    datepicker: new RouteInfo(AppRoutes.frameworkControlsRoot, "datepicker", { title: "Date / Time Picker" }),
    number: new RouteInfo(AppRoutes.frameworkControlsRoot, "number", { title: "Number" }),
    select: new RouteInfo(AppRoutes.frameworkControlsRoot, "select", { title: "Select" }),
    text: new RouteInfo(AppRoutes.frameworkControlsRoot, "text", { title: "Text" }),
    toggle: new RouteInfo(AppRoutes.frameworkControlsRoot, "toggle", { title: "Toggle" }),
    uploadFile: new RouteInfo(AppRoutes.frameworkControlsRoot, "upload-file", { title: "Upload File" }),

    // widgets
    button: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "button", { title: "Button" }),
    chart: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "chart", { title: "Chart" }),
    confirmationPopover: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "confirmation-popover", {
      title: "Confirmation Popover",
    }),
    dataTables: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "data-tables", { title: "Data Tables" }),
    dialog: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "dialog", { title: "Dialog" }),
    notify: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "notify", { title: "Notify" }),
    popover: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "popover", { title: "Popover" }),
    tooltip: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "tooltip", { title: "Tooltip" }),

    // layouts
    layoutTable: new RouteInfo<[string]>(AppRoutes.frameworkLayoutsRoot, "table", {
      title: "Table",
      defaultQueryParams: { type: "sidebar" },
    }),
    layoutTableEditable: new RouteInfo(AppRoutes.frameworkLayoutsRoot, "table-editable", {
      title: "Editable Table",
      defaultQueryParams: { type: "sidebar" },
    }),
    layoutCards: new RouteInfo<[string?]>(AppRoutes.frameworkLayoutsRoot, "cards/:?view", {
      title: "Cards",
      defaultQueryParams: { type: "sidebar" },
    }),
    layoutReport: new RouteInfo<[string?]>(AppRoutes.frameworkLayoutsRoot, "report/:?view", {
      title: "Report",
      defaultQueryParams: { type: "sidebar", showViewLinks: true, showActions: false },
    }),
    layoutUtilities: new RouteInfo<[string?]>(AppRoutes.frameworkLayoutsRoot, "layout-utilities/:?view", {
      title: "Utilities",
    }),
  };
  // #endregion
}
