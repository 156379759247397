<span
  class="fd-loading-label"
  [class.is-loading]="isLoading"
  [class.has-icon]="icon"
  [class.is-appended]="append"
  [style.--fd-loading-label-size]="size">
  <mat-icon *ngIf="!isLoading && icon" class="fd-loading-label-icon">{{ icon }}</mat-icon>
  <mat-icon *ngIf="isLoading && !append" class="fd-loading-label-spinner">
    <mat-spinner [diameter]="size" />
  </mat-icon>
  <span class="fd-loading-label-content" #content>
    <ng-content />
  </span>
  <mat-icon *ngIf="isLoading && append" class="fd-loading-label-spinner">
    <mat-spinner [diameter]="size" />
  </mat-icon>
</span>
<span
  *ngIf="tooltip && !icon && isLoading && tooltipContent"
  [matTooltip]="tooltipContent"
  class="fd-loading-label-tooltip"></span>
