import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { ThemePalette } from "@angular/material/core";
import { MatMenu, MatMenuModule, MatMenuTrigger } from "@angular/material/menu";

@Component({
  selector: "fd-button",
  templateUrl: "./fd-button.component.html",
  styleUrls: ["./fd-button.component.scss"],
  standalone: true,
  imports: [MatButtonModule, CommonModule, MatMenuModule],
})
export class FdButtonComponent {
  @Input() disabled = false;
  @Input() color: ThemePalette;
  @Input() matButton: "basic" | "raised" | "stroked" | "flat" | "fab" | "mini-fab" = "basic";
  @Input() type: "button" | "submit" = "button";
  @Input() menu: MatMenu;
  @Output() clicked = new EventEmitter<MouseEvent>();

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
}
