import { Component, Input } from "@angular/core";
import { AppEnvironment } from "src/app/enums/repository/app-environment.enum";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-environment-chip",
  templateUrl: "./environment-chip.component.html",
  styleUrls: ["./environment-chip.component.scss"],
})
export class EnvironmentChipComponent {
  @Input() clientEnvironment: AppEnvironment;
  @Input() serverEnvironment: AppEnvironment;

  readonly environment = environment;

  AppEnvironment = AppEnvironment;

  get appEnvironment(): AppEnvironment {
    return this.serverEnvironment === undefined ? this.clientEnvironment : this.serverEnvironment;
  }
}
