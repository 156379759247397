<div
  class="flex-spaced align-items-center mat-dense {{
    elevated ? 'mb-3 surface-base is-elevated rounded-t-none' : 'border-top'
  }}">
  <ng-content />
  <div class="p-2 d-none d-sm-block">
    <ng-content select="fd-table-exporter" />
  </div>
  <div class="ms-auto">
    <ng-content select="mat-paginator" />
  </div>
</div>
