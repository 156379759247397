import { Directive, ElementRef, HostListener, OnInit } from "@angular/core";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[mat-cell]",
  standalone: true,
})
export class MatCellDirective implements OnInit {
  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    if (
      this.el.nativeElement.closest(
        "table[fdKeyboardNavigation]:not([selectionMode=fd-editable-cell]):not([selectionMode=input]) tbody"
      )
    ) {
      this.el.nativeElement.tabIndex = 0;
    }

    if (this.el.nativeElement.querySelector("fd-editable-cell")) {
      this.el.nativeElement.setAttribute("fd-editable-cell-wrapper", true);
    }
  }

  @HostListener("focusin")
  onFocusIn() {
    this.el.nativeElement.setAttribute("has-focus", true);

    // Adjust table's scroll position if sticky headers or footers are in use.
    const table = this.el.nativeElement.closest("table");
    // mat-mdc-table-sticky is always assigned so we have to check for specific dom changes.
    if (table.querySelector(".mat-mdc-table-sticky").style["z-index"] > 1) {
      const header = table.querySelector("thead");
      const footer = table.querySelector("tfoot");
      const left = this.el.nativeElement.closest("tr").querySelector(".mat-mdc-table-sticky-border-elem-left");
      const right = this.el.nativeElement.closest("tr").querySelector(".mat-mdc-table-sticky-border-elem-right");
      const enabled = {
        header: !!header.querySelector("tr"),
        footer: !!footer.querySelector("tr"),
        left: !!left && !this.el.nativeElement.closest("td.mat-mdc-table-sticky-border-elem-left"),
        right: !!right && !this.el.nativeElement.closest("td.mat-mdc-table-sticky-border-elem-right"),
      };

      const stickyOffset = { header: 0, footer: 0, left: 0, right: 1 };
      const edge = {
        header: enabled.header ? header.getBoundingClientRect().bottom + stickyOffset.header : 0,
        footer: enabled.footer ? footer.getBoundingClientRect().top - stickyOffset.footer : 0,
        left: enabled.left ? left.getBoundingClientRect().right - stickyOffset.left : 0,
        right: enabled.right ? right.getBoundingClientRect().left - stickyOffset.right : 0,
      };
      const diff = {
        top: enabled.header
          ? this.el.nativeElement.getBoundingClientRect().top - edge.header - table.parentNode.scrollTop
          : 0,
        bottom: enabled.footer ? this.el.nativeElement.getBoundingClientRect().bottom - edge.footer : 0,
        left: enabled.left ? this.el.nativeElement.getBoundingClientRect().left - edge.left : 0,
        right: enabled.right ? this.el.nativeElement.getBoundingClientRect().right - edge.right : 0,
      };

      if (diff.top < 0) {
        table.parentNode.scrollBy(0, diff.top);
      } else if (diff.bottom > 0) {
        table.parentNode.scrollBy(0, diff.bottom);
      }
      if (diff.left < 0) {
        table.parentNode.scrollBy(diff.left, 0);
      } else if (diff.right > 0) {
        table.parentNode.scrollBy(diff.right, 0);
      }
    }
  }

  @HostListener("focusout")
  onFocusOut() {
    this.el.nativeElement.removeAttribute("has-focus");
  }
}
