<div
  class="flex-fill overflow-auto"
  [class.py-3]="!surface"
  [class.my-3]="surface"
  [class.surface-base]="surface"
  [class.always-show-scrollbar]="alwaysShowScrollbar"
  *ngIf="scrolling; else content">
  <ng-container [ngTemplateOutlet]="content" />
</div>

<ng-template #content>
  <div
    [class.flex-fill]="!scrolling"
    [class.surface-base]="surface && !scrolling"
    [class.my-3]="surface && !scrolling"
    class="{{
      (parent?.config.type !== 'sidebar' ? container ?? parent?.config.container : 'none') | containerClassName
    }}">
    <ng-content />
  </div>
</ng-template>
