import { Component, Input } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { IRouteInfo, RouteInfo } from "src/app/helpers/routes/route-info.model";

@Component({
  selector: "app-nav-item",
  templateUrl: "./nav-item.component.html",
  styleUrls: ["./nav-item.component.scss"],
})
export class NavItemComponent {
  @Input() link: IRouteInfo;
  @Input() level: number;
  @Input() drawer: MatSidenav;
  @Input() isHidden: boolean;

  routerLink(link: IRouteInfo) {
    if (link instanceof RouteInfo) {
      return link.buildRouterLink();
    }
  }

  queryParams(link: IRouteInfo) {
    if (link instanceof RouteInfo) {
      return link.buildQueryParams(link.data.defaultQueryParams);
    }
  }
}
