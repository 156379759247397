<mat-toolbar color="primary" class="mat-elevation-z1">
  <div *ngIf="userContext">
    <button mat-icon-button (click)="toggleDrawer()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>

  <img *ngIf="!userContext" src="assets/images/logo-white.png" class="d-inline-block align-top" alt="{{ appTitle }}" />

  <ng-container *ngIf="clientEnvironment !== AppEnvironment.Production">
    <span class="spacer"></span>
    <app-environment-chip [clientEnvironment]="clientEnvironment" [serverEnvironment]="serverEnvironment" />
  </ng-container>

  <ng-container *ngIf="userContext">
    <span class="spacer"></span>

    <button
      *ngIf="isImpersonatingUser"
      class="d-none d-sm-block"
      mat-flat-button
      color="warn"
      matTooltip="Revert Impersonation"
      (click)="abortImpersonate()">
      Impersonating {{ fullName }}
    </button>

    <button mat-button [matMenuTriggerFor]="userMenu" class="pe-0">
      <span class="d-flex align-items-center">
        {{ fullName }}
        <mat-icon>arrow_drop_down</mat-icon>
      </span>
    </button>

    <mat-menu #userMenu xPosition="before">
      <a mat-menu-item [routerLink]="AppRoutes.account.profile.routerLink">
        <mat-icon>account_circle</mat-icon>
        <span>Profile</span>
      </a>

      <button mat-menu-item (click)="signOut()">
        <mat-icon>logout</mat-icon>
        <span>Logout</span>
      </button>

      <ng-container *ngIf="environment.configuration !== AppEnvironment.Production && environment.configuration !== AppEnvironment.Beta">
        <mat-divider />

        <h3 class="roles-title" (click)="$event.stopPropagation()">Impersonate Roles</h3>

        <button
          *ngFor="let role of applicableRoles"
          mat-menu-item
          (click)="impersonateRole(false, role)"
          matTooltipPosition="left">
          <mat-icon>{{ userContext.isInRole(role) ? "check_box" : "check_box_outline_blank" }}</mat-icon>
          <span>{{ role }}</span>
        </button>

        <button
          mat-menu-item
          (click)="impersonateRole(true)"
          matTooltip="This option will show up whether or not you are actively impersonating any roles.">
          <span>Reset Role Impersonation</span>
        </button>
      </ng-container>
    </mat-menu>
  </ng-container>

  <ng-container *ngIf="showLogin">
    <span class="spacer"></span>

    <button mat-button routerLinkActive="active" [routerLink]="AppRoutes.login.routerLink">
      <mat-icon>login</mat-icon> Log In
    </button>
  </ng-container>
</mat-toolbar>
