import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { IEnvironment } from "../../environments/environment.definition";
import { AppEnvironment } from "../enums/repository/app-environment.enum";
import { AuthService } from "./auth/auth.service";
import { FdSnackBar } from "./fd-snack-bar.service";

@Injectable()
export class AppErrorHandler extends ErrorHandler {
  private handlingError: boolean = false;

  constructor(
    private snackBar: FdSnackBar,
    private environment: IEnvironment,
    private authService: AuthService,
    private router: Router,
    private zone: NgZone
  ) {
    super();
  }

  handleError(error: any) {
    if (!this.handlingError) {
      let message = "Sorry, there was an unhandled error.";
      let showRefresh = false;
      let showLogin = false;
      let showFullException = false;

      if (error?.errorType === "FailedToNegotiateWithServerError") {
        message = "The server hasn't been started yet. Please run the .NET server project.";
        showRefresh = true;
      } else if (error?.status !== undefined) {
        switch (error?.status) {
          case 0:
            showRefresh = true;
            message = "Sorry, the server couldn't be reached. Please check your internet connection.";
            break;
          case 400:
            message = error?.error ?? "Sorry, we couldn't process your request.";
            break;
          case 401:
            // don't show an error when the user is not authenticated. Just redirect them to login.
            console.warn("Received a 401 error. Redirecting to login.");
            return this.authService.redirectForLogin();

          case 403:
            showRefresh = true;
            message = "Sorry, you are not allowed to access this resource.";
            break;
          case 413:
            message = "Sorry, your request was too large for us to handle.";
            break;
          case 500:
            showRefresh = true;
            showFullException = true;
            message =
              (this.environment.configuration !== AppEnvironment.Production
                ? error?.error?.title ?? error?.error
                : null) || "Sorry, the server had an unexpected error.";
        }
      } else if (error instanceof Error && this.environment.configuration !== AppEnvironment.Production) {
        message = error.message;
        showFullException = true;
      }

      this.zone.run(() => {
        // we must ensure that the snackbar runs in the angular zone otherwise it may not render correctly
        if (this.environment.configuration === AppEnvironment.Production || !showFullException) {
          if (showRefresh) {
            this.snackBar
              .openWarn(message, showRefresh ? "Refresh" : null)
              .onAction()
              .subscribe(() => window.location.reload());
          } else if (showLogin) {
            this.snackBar
              .openWarn(message, "Login")
              .onAction()
              .subscribe(() => this.authService.signOut().subscribe(() => this.router.navigateByUrl("/login")));
          } else {
            this.snackBar.openWarn(message);
          }
        } else {
          this.snackBar
            .openWarn(message, "View Error", { duration: 5000 })
            .onAction()
            .subscribe(() => window.alert(error?.error?.detail || error?.error || error?.stack));
        }
      });
    }

    super.handleError(error);
  }
}
