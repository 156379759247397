import { LayoutModule } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ErrorHandler, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MAT_PAGINATOR_DEFAULT_OPTIONS } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from "@angular/material/snack-bar";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import { MtxDatetimepickerModule } from "@ng-matero/extensions/datetimepicker";
import { ReactiveFormConfig } from "@rxweb/reactive-form-validators";
import { MatTableExporterModule } from "mat-table-exporter";
import { CookieService } from "ngx-cookie-service";
import { environment } from "../environments/environment";
import { IEnvironment } from "../environments/environment.definition";
import { AppRoutingModule } from "./app-routing.module";
import { AppNavigationComponent } from "./components/app-navigation/app-navigation.component";
import { NavItemComponent } from "./components/app-navigation/nav-item/nav-item.component";
import { NestedNavItemComponent } from "./components/app-navigation/nested-nav-item/nested-nav-item.component";
import { AppToolbarComponent } from "./components/app-toolbar/app-toolbar.component";
import { EnvironmentChipComponent } from "./components/app-toolbar/environment-chip/environment-chip.component";
import { AppComponent } from "./components/app.component";
import { DirtyFormDialogComponent } from "./components/dirty-form-dialog/dirty-form-dialog.component";
import { HomeComponent } from "./components/home/home.component";
import { LoginComponent } from "./components/login/login.component";
import { NotAuthorizedComponent } from "./components/not-authorized/not-authorized.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { RedirectComponent } from "./components/redirect/redirect.component";
import dateProviders from "./helpers/constants/date-config.constants";
import { FdSpinnerComponent } from "./modules/shared/components/fd-spinner/fd-spinner.component";
import { LoadingLabelComponent } from "./modules/shared/components/loading-label/loading-label.component";
import { SharedModule } from "./modules/shared/shared.module";
import { ApiHttpInterceptor } from "./services/api/api.http-interceptor";
import { AppErrorHandler } from "./services/app-error.handler";
import { NgChartsModule } from "ng2-charts";

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    NotAuthorizedComponent,
    NotFoundComponent,
    EnvironmentChipComponent,
    AppNavigationComponent,
    AppToolbarComponent,
    RedirectComponent,
    DirtyFormDialogComponent,
    NavItemComponent,
    NestedNavItemComponent,
  ],
  imports: [
    AppRoutingModule,
    SharedModule,

    // Angular
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    // Angular Material
    MatButtonModule,
    MatBadgeModule,
    MatCheckboxModule,
    MatCardModule,
    MatChipsModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatBadgeModule,
    MatTooltipModule,

    LayoutModule,

    // @ng-matero/extensions
    MtxDatetimepickerModule,

    // forRoot()
    MatTableExporterModule.forRoot({ xlsxLightWeight: true }),

    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: false, // set this to false if the app should not be a PWA, otherwise you should environment.production
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),

    // Standalone Components
    FdSpinnerComponent,
    LoadingLabelComponent,
    NgChartsModule,
  ],
  providers: [
    ...dateProviders,
    CookieService,
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: IEnvironment, useValue: environment },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        horizontalPosition: "right",
        verticalPosition: "bottom",
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiHttpInterceptor,
      multi: true,
    },
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: environment.matPaginatorDefaultOptions,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: environment.matFormFieldDefaultOptions,
    },
  ],
})
export class AppModule {
  constructor() {
    ReactiveFormConfig.set({
      internationalization: {
        dateFormat: "mdy",
        seperator: "/",
      },
      validationMessage: {
        range: "This field must be between {{1}} and {{2}}.",
        required: "This field is required.",
        minLength: "A minimum of {{1}} character(s) are allowed.",
        maxLength: "A maximum of {{1}} character(s) are allowed.",
        minNumber: "A minimum number of {{1}} is allowed.",
        maxNumber: "A maximum number of {{1}} is allowed.",
        minDate: "A minimum date of {{1}} is allowed.",
        maxDate: "A maximum date of {{1}} is allowed.",
        notEmpty: "This field cannot be empty.",
        requiredTrue: "This field must be checked.",
        alpha: "Only letters are allowed.",
        numeric: "Only numbers are allowed.",
        alphaNumeric: "Only letters and numbers are allowed.",
        ascii: "Only ASCII characters are allowed.",
        url: "Invalid URL format.",
        email: "Invalid email format.",
        password: "Your password is not strong enough.",
        compare: "This value doesn't match.",
        matDatepickerParse: "The format is invalid.",
        conflict: "This record conflicts with another.",
      },
    });
  }
}
