import { Component } from "@angular/core";
import { FdRouterService } from "../services/fd-router.service";
import { FdTitleService } from "../services/fd-title.service";
import { GlobalLoadingIndicatorService } from "../services/global-loading-indicator.service";
import { LocalStoreManager } from "../services/local-store-manager.service";
import { OnlineStatusService } from "../services/online-status.service";
import { BaseComponent } from "./base.component";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent extends BaseComponent {
  showGlobalLoadingIndicator = false;
  isLoadingLazyModule = false;
  appTitle = document.title;

  constructor(
    storageManager: LocalStoreManager,
    private appTitleService: FdTitleService,
    private globalLoadingIndicatorService: GlobalLoadingIndicatorService,
    public fdRouterService: FdRouterService,
    private onlineStatusService: OnlineStatusService
  ) {
    super();

    storageManager.initialiseStorageSyncListener();

    this.appTitleService.appName = this.appTitle;

    // subscribe to routing events to trigger a busy spinner on loading a lazy module
    this.fdRouterService.routeConfigLoadStart$.subscribe(() => (this.isLoadingLazyModule = true));
    this.fdRouterService.routeConfigLoadEnd$.subscribe(() => (this.isLoadingLazyModule = false));
    this.fdRouterService.navigationCancel$.subscribe(() => (this.isLoadingLazyModule = false));
    this.fdRouterService.navigationError$.subscribe(() => (this.isLoadingLazyModule = false));

    // subscribe to loading events to trigger a busy progress bar
    this.globalLoadingIndicatorService.isLoading$.subscribe(isLoading =>
      setTimeout(() => (this.showGlobalLoadingIndicator = isLoading))
    );

    // Begin tracking network connectivity status
    this.onlineStatusService.initialize();
  }
}
