import { CommonModule } from "@angular/common";
import { AfterContentInit, AfterViewInit, Component, ContentChild } from "@angular/core";
import { MatButton, MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatMenu, MatMenuModule } from "@angular/material/menu";
import { FdButtonComponent } from "../fd-button/fd-button.component";

@Component({
  selector: "fd-split-button",
  standalone: true,
  imports: [MatButtonModule, CommonModule, MatDividerModule, MatMenuModule, MatIconModule, FdButtonComponent],
  templateUrl: "./split-button.component.html",
  styleUrls: ["./split-button.component.scss"],
})
export class SplitButtonComponent implements AfterContentInit, AfterViewInit {
  @ContentChild(MatButton) matButton: MatButton;
  @ContentChild(MatMenu) matMenu: MatMenu;

  get matButtonType() {
    const buttonElement = this.matButton._elementRef.nativeElement as HTMLButtonElement;
    if (buttonElement.getAttribute("mat-flat-button") !== null) {
      return "flat";
    } else if (buttonElement.getAttribute("mat-stroked-button") !== null) {
      return "stroked";
    } else if (buttonElement.getAttribute("mat-raised-button") !== null) {
      return "raised";
    }

    return "basic";
  }

  ngAfterContentInit(): void {
    const errorBuilder = [];

    if (!this.matButton) {
      errorBuilder.push(`SplitButtonComponent expects content child MatButton.`);
    }
    if (!this.matMenu) {
      errorBuilder.push(`SplitButtonComponent expects content child MatMenu.`);
    }

    if (errorBuilder.length) {
      errorBuilder.push(
        "Example:",
        `<fd-split-button>
    <button matButton></button>
    <mat-menu></mat-menu>
</fd-split-button>`
      );

      throw errorBuilder.join("\n");
    }
  }

  ngAfterViewInit(): void {
    const matMenuElement = this.matMenu.templateRef.elementRef.nativeElement.parentElement as HTMLElement;
    if (matMenuElement.getAttribute("xposition") === null) {
      this.matMenu.xPosition = "before";
    }
  }
}
