<ng-container [ngSwitch]="matButton">
  <button
    *ngSwitchCase="'basic'"
    mat-button
    [type]="type"
    [color]="color"
    [disabled]="disabled"
    [matMenuTriggerFor]="menu"
    (click)="clicked.emit($event)">
    <ng-container *ngTemplateOutlet="content" />
  </button>
  <button
    *ngSwitchCase="'stroked'"
    mat-stroked-button
    [type]="type"
    [color]="color"
    [disabled]="disabled"
    [matMenuTriggerFor]="menu"
    (click)="clicked.emit($event)">
    <ng-container *ngTemplateOutlet="content" />
  </button>
  <button
    *ngSwitchCase="'flat'"
    mat-flat-button
    [type]="type"
    [color]="color"
    [disabled]="disabled"
    [matMenuTriggerFor]="menu"
    (click)="clicked.emit($event)">
    <ng-container *ngTemplateOutlet="content" />
  </button>
  <button
    *ngSwitchCase="'raised'"
    mat-raised-button
    [type]="type"
    [color]="color"
    [disabled]="disabled"
    [matMenuTriggerFor]="menu"
    (click)="clicked.emit($event)">
    <ng-container *ngTemplateOutlet="content" />
  </button>
  <button
    *ngSwitchCase="'fab'"
    mat-fab
    [type]="type"
    [color]="color"
    [disabled]="disabled"
    [matMenuTriggerFor]="menu"
    (click)="clicked.emit($event)">
    <ng-container *ngTemplateOutlet="content" />
  </button>
  <button
    *ngSwitchCase="'mini-fab'"
    mat-mini-fab
    [type]="type"
    [color]="color"
    [disabled]="disabled"
    [matMenuTriggerFor]="menu"
    (click)="clicked.emit($event)">
    <ng-container *ngTemplateOutlet="content" />
  </button>
</ng-container>

<ng-template #content>
  <ng-content />
</ng-template>
