import { Pipe, PipeTransform } from "@angular/core";
import { ContainerSize } from "src/app/helpers/types/layouts.type";

@Pipe({
  name: "containerClassName",
  standalone: true,
})
export class ContainerClassNamePipe implements PipeTransform {
  transform(value: ContainerSize | null, ...args: unknown[]): unknown {
    if (value === "none") return "";
    return !value || value === "default" ? "container" : `container-${value}`;
  }
}
