import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
} from "@angular/core";
import { NgControl } from "@angular/forms";
import { Subscription } from "rxjs";
import { HtmlInputType } from "src/app/helpers/types/html-input-type.type";
import { HtmlInputmode } from "src/app/helpers/types/html-inputmode.type";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "input",
  standalone: true,
})
export class InputDirective implements OnInit, OnChanges, OnDestroy {
  /** Defaults to using "new-password" instead of "off" due to this chromium bug: https://bugs.chromium.org/p/chromium/issues/detail?id=468153#c164 */
  @HostBinding() @Input() autocomplete = "new-password";
  @HostBinding() @Input() type: HtmlInputType | string = "";
  @HostBinding() @Input() step: number | string = "";
  @HostBinding() @Input() inputmode: HtmlInputmode | string = "";

  @Input() fdPrecision: number | string;
  @Input() revertOnEscape: boolean = true;

  private subscriptions: Subscription[] = [];
  private initialValue: string;

  constructor(private host: ElementRef<HTMLInputElement>, @Optional() private control: NgControl) {}

  ngOnInit(): void {
    if (this.control) {
      this.subscriptions.push(this.control.valueChanges.subscribe(() => this.ngOnChanges()));
    }
    this.ngOnChanges();
  }

  ngOnChanges(): void {
    // set the input type first so that the input mode can be determined based on that value
    this.type = this.setInputType();
    this.inputmode = this.setInputmode();

    if (this.inputmode) {
      this.host.nativeElement.setAttribute("inputmode", this.inputmode);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  @HostListener("focus", ["$event"])
  focus($event: KeyboardEvent) {
    this.initialValue = this.host.nativeElement.value;
  }

  @HostListener("keydown", ["$event"])
  keydown($event: KeyboardEvent) {
    if (this.revertOnEscape && $event.code == "Escape") {
      this.host.nativeElement.value = this.initialValue;
      this.control?.control?.setValue(this.initialValue);
    }
  }

  private setInputType(): HtmlInputType | string {
    if (this.type) {
      return this.type; // don't override the type value.
    }

    if (this.autocomplete) {
      // try to set the input type based on the autocomplete value
      switch (this.autocomplete) {
        case "email":
          return "email";
        case "tel":
        case "tel-local":
        case "tel-national":
          return "tel";
        case "url":
        case "photo":
          return "url";
        case "bday-day":
        case "bday-month":
        case "bday-year":
          return "number";
        case "current-password": // case "new-password": - not including new-password since that can be used to just turn "off" autocomplete functionality without it really being a password type
          return "password";
        case "off":
        case "on":
          break; // do not set the type based on these values
        default:
          // everything else can be assumed to be a text type
          return "text";
      }
    }

    if (this.control) {
      // try to determine the type of the input based on current value
      switch (typeof this.control.value) {
        case "string":
          return "text";
        case "number":
        case "bigint":
          return "number";
      }

      if (this.control.enabled) {
        // don't warn about disabled form controls because they may always have a value once enabled
        console.warn(
          `Form control's (${this.control.name}) input type could not be determined automatically. Set a type attribute explicitly.`
        );
      }
    } else {
      console.warn(
        `Input type could not be determined automatically since a form control was not bound. Either bind a form control or set a type attribute explicitly.`
      );
    }

    return this.type;
  }

  private setInputmode(): HtmlInputmode | string {
    if (this.inputmode) {
      return this.inputmode; // don't override the inputmode value.
    }

    switch (this.type) {
      case "number":
        if (!this.step) {
          // chrome will show a validation error on numerical inputs that are not whole numbers which do not have a step value within range.
          this.step = "any";
        }
        return this.isDecimalInput() ? "decimal" : "numeric";

      case "email":
        return "email";
      case "search":
        return "search";
      case "tel":
        return "tel";
      case "url":
        return "url";
      default:
        return "text";
    }
  }

  private isDecimalInput() {
    if (+this.step % 1 > 0) {
      return true;
    }

    if (+this.fdPrecision > 0) {
      return true;
    }

    return false;
  }
}
