import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { finalize } from "rxjs/operators";
import { AppRoutes } from "src/app/routes";
import { IEnvironment } from "../../../environments/environment.definition";
import { AppEnvironment } from "../../enums/repository/app-environment.enum";
import { AuthService } from "../../services/auth/auth.service";
import { FdSnackBar } from "../../services/fd-snack-bar.service";
import { LocalStoreManager } from "../../services/local-store-manager.service";
import { UserLogin } from "./user-login.model";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy {
  AppRoutes = AppRoutes;

  userLogin = new UserLogin();
  isLoading = false;
  formResetToggle = true;
  dialogClosedCallback: () => void;
  loginStatusSubscription: any;

  @Input()
  isDialog = false;

  showRegister = false;
  showMicrosoftLogin = true;
  showGoogleLogin = false;
  showLocalLogin = false;
  showForgotPassword = true;
  showRememberMe = false;

  constructor(
    private snackBar: FdSnackBar,
    private authService: AuthService,
    private environment: IEnvironment,
    private localStoreManager: LocalStoreManager
  ) {}

  get isDevelopment() {
    return this.environment.configuration === AppEnvironment.Development;
  }

  ngOnInit() {
    if (!this.showRememberMe) {
      // if the user doesn't have a choice, always remember the user.
      this.authService.rememberMe = true;
    }

    this.userLogin.rememberMe = this.authService.rememberMe;

    const { wholePart } = this.authService.getReturnUrl();
    this.localStoreManager.saveSessionData(wholePart, LocalStoreManager.DBKEY_RETURN_URL);

    if (this.getShouldRedirect()) {
      this.authService.redirectLoginUser();
    } else {
      this.loginStatusSubscription = this.authService.getLoginStatusEvent().subscribe(isLoggedIn => {
        if (this.getShouldRedirect()) {
          this.authService.redirectLoginUser();
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.loginStatusSubscription) {
      this.loginStatusSubscription.unsubscribe();
    }
  }

  getShouldRedirect() {
    return !this.isDialog && this.authService.isLoggedIn;
  }

  showErrorAlert(message: string) {
    this.snackBar.open(message);
  }

  closeDialog() {
    if (this.dialogClosedCallback) {
      this.dialogClosedCallback();
    }
  }

  signIn(backdoor: boolean = false) {
    this.isLoading = true;
    this.snackBar.open("Attempting login...");

    this.authService
      .signIn(this.userLogin.userName, this.userLogin.password, this.userLogin.rememberMe, backdoor)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(user => {
        setTimeout(() => {
          this.snackBar.dismiss();
          this.isLoading = false;
          this.reset();

          if (!this.isDialog) {
            this.snackBar.open(`Welcome ${user.firstName}!`);
          } else {
            this.snackBar.open(`Session for ${user.firstName} restored!`);
            setTimeout(() => {
              this.snackBar.openAccent(`Session for ${user.firstName} restored! Please try your last operation again.`);
            }, 500);

            this.closeDialog();
          }
        }, 500);
      });
  }

  mapLoginErrorMessage(error: string) {
    if (error === "invalid_username_or_password") {
      return "Invalid username or password";
    }

    if (error === "invalid_grant") {
      return "This account has been disabled";
    }

    return error;
  }

  reset() {
    this.formResetToggle = false;

    setTimeout(() => {
      this.formResetToggle = true;
    });
  }

  rememberMeChange() {
    this.authService.rememberMe = this.userLogin.rememberMe;
  }
}
