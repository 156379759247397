import { AfterViewInit, Directive, ElementRef, Input } from "@angular/core";

@Directive({
  /* eslint-disable */
  selector: "[autofocus]", // using native attribute name here since it isn't supported in CSR anyway and is intuitive
  standalone: true,
})
export class AutofocusDirective implements AfterViewInit {
  private _autofocus = true;

  @Input() set autofocus(condition: boolean | string) {
    this._autofocus = condition !== false; // condition could be omitted, so in that case autofocus should be true
  }

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    // we are autofocusing inside the ngAfterViewInit lifecycle event to ensure that the input has been fully initialized
    if (this._autofocus) {
      // we need to wrap the call to focus() in a setTimeout to avoid the "content changed after it's been checked" angular error
      setTimeout(() => this.el.nativeElement.focus());
    }
  }
}
