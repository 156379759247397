import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, ViewChild, inject } from "@angular/core";
import { ThemePalette } from "@angular/material/core";
import { MatMenuModule } from "@angular/material/menu";
import { ExportType, MatTableExporterDirective } from "mat-table-exporter";
import { Observable, Subscription } from "rxjs";
import { finalize } from "rxjs/operators";
import { FdSnackBar } from "src/app/services/fd-snack-bar.service";
import { FdButtonComponent } from "../fd-button/fd-button.component";
import { LoadingLabelComponent } from "../loading-label/loading-label.component";

type ExportOption = {
  format: ExportType;
  text: string;
  exporter?: MatTableExporterDirective | Observable<unknown>;
  subscription?: Subscription;
};
export type Exporter = { format: ExportType; exporter?: MatTableExporterDirective | Observable<unknown> };

@Component({
  selector: "fd-table-exporter",
  templateUrl: "./table-exporter.component.html",
  styleUrls: ["./table-exporter.component.scss"],
  standalone: true,
  imports: [CommonModule, FdButtonComponent, LoadingLabelComponent, MatMenuModule],
})
export class TableExporterComponent implements AfterViewInit, OnDestroy {
  // export inputs
  /**
   * Provide a list of supported export types and if applicable the client-side exporter for that type.
   * If you just provide a MatTableExporterDirective, then we will support CSV exports only.
   */
  @Input({ required: true }) exporters: MatTableExporterDirective | Exporter[];
  @Input() fileName: string = "Export";

  // button inputs
  @Input() disabled = false;
  @Input() color: ThemePalette = "accent";
  @Input() matButton: "basic" | "raised" | "stroked" | "flat" = "stroked";

  // outputs
  @Output() completed = new EventEmitter<ExportType>();
  @Output() cancel = new EventEmitter<ExportType>();
  /** If exporter is null, then the resolver will be available for the caller to complete the export process */
  @Output() export = new EventEmitter<{
    format: ExportType;
  }>();

  @ViewChild(FdButtonComponent) button: FdButtonComponent;

  private readonly snackBar = inject(FdSnackBar);
  private readonly subscriptions: Subscription[] = [];
  private static texts = new Map<ExportType, string>([
    [ExportType.CSV, "CSV"],
    [ExportType.JSON, "json"],
    [ExportType.OTHER, "Other"],
    [ExportType.TXT, "Text"],
    [ExportType.XLS, "Excel (97-03)"],
    [ExportType.XLSX, "Excel"],
  ]);

  protected isExportingOption: ExportOption = null;
  protected options: ExportOption[] = [];

  ngAfterViewInit(): void {
    // using setTimeout to avoid ExpressionChangedAfterChecked error
    setTimeout(() => {
      if (this.exporters instanceof MatTableExporterDirective) {
        this.options = [
          {
            format: ExportType.CSV,
            text: TableExporterComponent.texts.get(ExportType.CSV),
            exporter: this.exporters,
          },
        ];
      } else {
        this.options = this.exporters.map(e => ({
          format: e.format,
          text: TableExporterComponent.texts.get(e.format),
          exporter: e.exporter,
        }));
      }

      this.subscriptions.push(
        ...this.options.map(option => {
          if (option.exporter instanceof MatTableExporterDirective) {
            return option.exporter.exportCompleted.subscribe(() => this.completed.emit(this.isExportingOption.format));
          }
        }),
        this.completed.subscribe(() => (this.isExportingOption = null))
      );
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s?.unsubscribe());
  }

  protected exportData(option: ExportOption) {
    this.isExportingOption = option; // see ExportType definition for available types
    this.export.emit({ format: option.format });

    if (option.exporter instanceof MatTableExporterDirective) {
      option.exporter.exportTable(option.format, { fileName: this.fileName });
    } else {
      option.subscription = option.exporter.pipe(finalize(() => this.completed.emit(option.format))).subscribe();
      this.subscriptions.push(option.subscription);
    }
  }

  protected cancelExport() {
    this.cancel.emit(this.isExportingOption.format);
    this.button.menuTrigger.closeMenu();

    if (this.isExportingOption.exporter instanceof MatTableExporterDirective) {
      // in order to cancel the exporter, we need to hook into the private API
      const _exporter = this.isExportingOption.exporter as any;
      try {
        _exporter._isIterating = false;
        _exporter._isExporting = false;
        this.isExportingOption.exporter.goToPage(_exporter._initialPageIndex);
      } catch (e) {
        console.error(e);
        this.snackBar
          .openWarn("Failed to cancel export. Please refresh the page.", "Refresh")
          .onAction()
          .subscribe(location.reload);
      }
    } else {
      this.isExportingOption.subscription?.unsubscribe();
    }

    this.isExportingOption = null;
  }
}
